import styled from 'styled-components';

export const Title = styled.h2`
  font-size: 2.4rem;
  font-weight: 700;
  line-height: 1.17;
  text-transform: uppercase;
  color: ${({ theme }) => theme.color.textPrimary};

  @media screen and (max-width: ${({ theme }) => theme.breakPoints.sm.width}) {
    font-size: 1.6rem;
    line-height: 1.5;
  }
`;

export const Code = styled.div`
  margin-top: ${({ theme }) => theme.indent.s};
  font-size: 7.2rem;
  font-weight: 700;
  line-height: 1;
  color: ${({ theme }) => theme.color.borderPopup};
`;

export const Page = styled.section`
  display: flex;
  flex: 1 0 auto;
  align-items: center;
  padding: 60px 0;
  text-align: center;

  svg {
    display: block;
    width: 96px;
    height: 64px;
    margin: 10px auto 0;
  }

  a,
  button {
    margin-top: 20px;
  }
`;
