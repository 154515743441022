import styled from 'styled-components';
import { NavLink } from 'react-router-dom';

export const SignInLink = styled(NavLink)`
  font-size: 1.6rem;
  font-weight: 700;
  color: ${({ theme }) => theme.color.textSecondary};
  text-transform: uppercase;
  transition: color 0.2s ease-out;
  margin-right: 16px;

  @media screen and (max-width: ${({ theme }) => theme.breakPoints.md.width}) {
    display: none;
  }

  & > .svg-user {
    vertical-align: middle;
    width: 24px;
    height: 24px;
    margin-right: ${({ theme }) => theme.indent.s};
    fill: ${({ theme }) => theme.color.rgbaWhite('.8')};
    transition: fill 0.2s ease-out;
  }
  & > span {
    vertical-align: middle;
  }

  &:hover,
  &._active {
    color: ${({ theme }) => theme.color.textPrimary};
    & > .svg-user {
      fill: ${({ theme }) => theme.color.rgbaWhite('1')};
    }
  }

  &._active {
    pointer-events: none;
  }
`;

export default SignInLink;
