import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Item } from './styles';

const propTypes = {
  id: PropTypes.string.isRequired,
  type: PropTypes.oneOf(['info', 'warning']).isRequired,
  remove: PropTypes.func.isRequired,
  children: PropTypes.string.isRequired
};

const Toaster = ({ id, type, remove, children }) => {
  useEffect(() => {
    setTimeout(() => {
      remove(id);
    }, 10000);
  }, []);

  return (
    <Item key={id} className={`_${type}`}>
      {children}
    </Item>
  );
};

Toaster.propTypes = propTypes;
export default React.memo(Toaster);
