import React, { lazy } from 'react';
import PropTypes from 'prop-types';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import useConfig from 'app/hooks/useConfig';
import RouterContextComponent from 'app/RouterContext';

import Layout from 'app/containers/Layout';
import Error from 'app/containers/Error';
import ErrorBoundary from 'app/containers/ErrorBoundary';
import NoInternet from 'app/containers/NoInternet';
// MS
// eslint-disable-next-line no-unused-vars
import storage from 'app/services/session';

/**
 * Auth module
 */
const SignIn = lazy(() => import('app/modules/auth/SignIn'));
const SignUp = lazy(() => import('app/modules/auth/SignUp'));
const PasswordReset = lazy(() => import('app/modules/auth/PasswordReset'));

const Movies = lazy(() => import('app/modules/movies'));
const Cinemas = lazy(() => import('app/modules/cinemas'));
const Events = lazy(() => import('app/modules/events'));

const Search = lazy(() => import('app/modules/search'));
const Account = lazy(() => import('app/modules/account'));
const Purchase = lazy(() => import('app/modules/purchase'));
const Info = lazy(() => import('app/modules/info'));

const propTypes = {
  sendVisitedScreens: PropTypes.func.isRequired
};

const Routes = ({ sendVisitedScreens }) => {
  const { routes } = useConfig();

  return (
    <Router>
      <RouterContextComponent>
        <Layout listen={sendVisitedScreens}>
          <ErrorBoundary ErrorComponent={Error}>
            <Switch>
              <Route
                path="/"
                component={() => (
                  <Redirect
                    to={{
                      pathname: routes.movies.pattern
                    }}
                  />
                )}
                exact
              />
              <Route path={routes.login.pattern} component={SignIn} />
              <Route path={routes.registration.pattern} component={SignUp} />
              <Route path={routes.resetPassword.pattern} component={PasswordReset} />

              <Route path={routes.movies.pattern} component={Movies} />
              <Route path={routes.cinemas.pattern} component={Cinemas} />
              <Route path={routes.events.pattern} component={Events} />

              <Route path={routes.search.pattern} component={Search} />
              <Route path={routes.purchase.pattern} component={Purchase} />
              <Route path={routes.account.pattern} component={Account} />
              <Route path={routes.info.pattern} component={Info} />

              <Route path={routes.no_internet.pattern} component={NoInternet} />
              <Route component={Error} />
            </Switch>
          </ErrorBoundary>
        </Layout>
      </RouterContextComponent>
    </Router>
  );
};

Routes.propTypes = propTypes;
export default Routes;
