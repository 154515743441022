import send from 'app/services/HTTP';
import { handleErrorResponse, handleResponse } from './handlers';

/**
 * Build url for request
 *
 * @private
 * @param {string} endpoint
 * @param {string} version
 * @param {string} url
 * @returns {string}
 */
const buildUrl = (endpoint, version, url) => `${endpoint}${version}${url}`;

/**
 * Swaps object (schema) keys to values
 *
 * @param schema {object}
 * @returns {object}
 */
const invertSchema = schema => {
  const reverted = {};

  Object.keys(schema).forEach(key => {
    reverted[schema[key]] = key;
  });

  return reverted;
};

/**
 * Maps data according to scheme
 *
 * @param schema {object}
 * @param data {object}
 * @returns {object}
 */
const mapSchemaWithData = (schema, data) => {
  const connected = {};

  Object.keys(data).forEach(field => {
    if (Object.prototype.hasOwnProperty.call(schema, field)) {
      connected[schema[field]] = data[field];
    } else {
      connected[field] = data[field];
    }
  });

  return connected;
};

/**
 * Parent Class for API instances
 */
export default class BaseApi {
  static invertSchema = invertSchema;

  static mapSchemaWithData = mapSchemaWithData;

  /**
   * Creates Base API instance
   *
   * @constructor
   * @param endpoint {string}
   * @param version {string}
   * @param headers {object}
   * @param storage {Storage}
   */
  constructor({ endpoint, version, headers, storage, headerTypes }) {
    this.endpoint = endpoint;
    this.version = version;
    this.headers = headers;
    this.storage = storage;
    this.headerTypes = headerTypes;
  }

  getAccessToken() {
    return this.storage.getAccessToken();
  }

  setAccessToken(value) {
    this.storage.setAccessToken(value);
    this.addHeader(this.headerTypes.AUTHORIZATION, `Bearer ${value}`);
  }

  removeAccessToken() {
    this.storage.removeAccessToken();
    this.removeHeader(this.headerTypes.AUTHORIZATION);
  }

  getRefreshToken() {
    return this.storage.getRefreshToken();
  }

  setRefreshToken(value) {
    this.storage.setRefreshToken(value);
  }

  removeRefreshToken() {
    this.storage.removeRefreshToken();
  }

  getLocation() {
    return this.storage.getLocation();
  }

  getLanguage() {
    return this.storage.getLanguage();
  }

  getReqHeaders(headers = {}) {
    this.addHeader(this.headerTypes.ACCEPT_LANGUAGE, this.getLanguage());

    return {
      ...this.headers,
      ...headers
    };
  }

  withHTML(method) {
    return args => {
      const params = {
        ...args,
        headers: {
          ...args.headers,
          [this.headerTypes.CONTENT_TYPE]: 'text/html; charset=UTF-8'
        }
      };

      return method.call(this, params);
    };
  }

  /**
   *  Adds property to the stored headers
   *
   * @param key {string}
   * @param value {string}
   */
  addHeader(key, value) {
    this.headers[key] = value;
  }

  /**
   * Removes property from stored headers
   *
   * @param key {string}
   */
  removeHeader(key) {
    delete this.headers[key];
  }

  /**
   * Makes GET HTTP request
   *
   * @param url {string}
   * @param params {object|undefined}
   * @param headers {object|undefined}
   * @param version {string|undefined}
   * @returns {Promise<*|Error>}
   */
  get({ url, params = {}, headers, version }) {
    const method = 'GET';

    return this.send({
      url,
      method,
      query: params,
      headers,
      version
    });
  }

  /**
   * Makes POST HTTP request
   *
   * @param url {string}
   * @param body {object}
   * @param params {object|undefined}
   * @param headers {object|undefined}
   * @param version {string|undefined}
   * @returns {Promise<*|Error>}
   */
  post({ url, body, params, headers, version }) {
    const method = 'POST';

    return this.send({
      url,
      method,
      body,
      query: params,
      headers,
      version
    });
  }

  /**
   * Makes PUT HTTP request
   *
   * @param url {string}
   * @param body {object}
   * @param headers {object|undefined}
   * @param version {string|undefined}
   * @returns {Promise<*|Error>}
   */
  put({ url, body, headers, version }) {
    const method = 'PUT';

    return this.send({
      url,
      method,
      body,
      headers,
      version
    });
  }

  /**
   * Makes PATCH HTTP request
   *
   * @param url {string}
   * @param body {object}
   * @param headers {object|undefined}
   * @param version {string|undefined}
   * @returns {Promise<*|Error>}
   */
  patch({ url, body, headers, version }) {
    const method = 'PATCH';

    return this.send({
      url,
      method,
      body,
      headers,
      version
    });
  }

  /**
   * Makes DELETE HTTP request
   *
   * @param url {string}
   * @param body {object}
   * @param headers {object|undefined}
   * @param version {version|undefined}
   * @returns {Promise<*|Error>}
   */
  remove({ url, body = null, headers = null, version }) {
    const method = 'DELETE';

    return this.send({
      url,
      method,
      body,
      headers,
      version
    });
  }

  /**
   * Calls network request
   *
   * @private
   * @param method {string}
   * @param url {string}
   * @param body {object|undefined}
   * @param query {object|undefined}
   * @param headers {object|undefined}
   * @param version {string|undefined}
   * @returns {Promise<*|Error>}
   */
  send({ method, url, body, query, headers = {}, version }) {
    const uri = buildUrl(this.endpoint, version || version === '' ? version : this.version, url);
    const reqHeaders = this.getReqHeaders(headers);

    return send({
      method,
      body,
      query,
      url: uri,
      headers: reqHeaders
    })
      .then(handleResponse)
      .catch(handleErrorResponse);
  }
}
