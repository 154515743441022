import {
  CINEMAS_SUCCESS,
  SET_CINEMAS_WITH_MOVIES,
  TOGGLE_FAVORITE_SUCCESS,
  SET_DATES,
  SET_CINEMA,
  SET_MOVIE_SESSIONS
} from './types';

const initialState = {
  cinemas: [],
  cinema: null,
  sessions: [],
  withMovies: [],
  dates: [],
  favoriteList: []
};

export default function(state = initialState, { type, payload }) {
  switch (type) {
    case SET_CINEMAS_WITH_MOVIES: {
      return {
        ...state,
        withMovies: payload.cinemas || []
      };
    }

    case CINEMAS_SUCCESS: {
      return {
        ...state,
        cinemas: payload.cinemas.sort((a, b) => b.favorite - a.favorite)
      };
    }

    case TOGGLE_FAVORITE_SUCCESS: {
      return {
        ...state,
        favoriteList: payload.favoriteList,
        cinemas: state.cinemas.map(cinema => ({
          ...cinema,
          favorite: cinema.id === payload.request.id ? payload.request.favorite : cinema.favorite
        }))
      };
    }

    case SET_CINEMA: {
      return {
        ...state,
        cinema: payload.cinema || []
      };
    }

    case SET_MOVIE_SESSIONS: {
      return {
        ...state,
        sessions: payload.sessions || []
      };
    }

    case SET_DATES: {
      return {
        ...state,
        dates: payload.dates || []
      };
    }

    default: {
      return state;
    }
  }
}
