import {
  INFORMATION_LEGAL_SET,
  INFORMATION_IMPRESSUM_SET,
  INFORMATION_B2B_SET,
  INFORMATION_PRICELIST_SET
} from './types';

const initialState = {
  legalInfoContent: [],
  impressumContent: null,
  b2bContent: null,
  priceListContent: null
};

export default function(state = initialState, { type, payload }) {
  switch (type) {
    case INFORMATION_LEGAL_SET: {
      return {
        ...state,
        legalInfoContent: payload.legalInfoContent
      };
    }

    case INFORMATION_IMPRESSUM_SET: {
      return {
        ...state,
        impressumContent: payload.impressumContent
      };
    }

    case INFORMATION_B2B_SET: {
      return {
        ...state,
        b2bContent: payload.b2bContent
      };
    }

    case INFORMATION_PRICELIST_SET: {
      return {
        ...state,
        priceListContent: payload.priceListContent
      };
    }

    default: {
      return state;
    }
  }
}
