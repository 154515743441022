/* eslint-disable */
export const cinemasDailySessions = (dates, cinemas) => {
  const schema = cinemas.reduce((acc, { id, favorite, name }) => {
    acc[id] = { id, favorite, name, sessions: [] };

    return acc;
  }, {});

  dates.forEach(day => {
    const { date, sessions } = day;
    const keys = Object.keys(schema);

    for (let i = 0; i < keys.length; i += 1) {
      const key = keys[i];
      const filteredSessions = sessions.filter(session => session.cinemaId === key);

      if (filteredSessions.length) {
        schema[key].sessions.push({ date, sessions: filteredSessions });
      }
    }
  });

  return Object.values(schema);
};
