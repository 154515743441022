import styled from 'styled-components';

export default styled.div.attrs({
  className: 'b-movies-filter'
})`
  ${({ theme, withPadding }) => withPadding && `padding: ${theme.indent.l} 0;`}
  text-align: right;

  @media screen and (max-width: ${({ theme }) => theme.breakPoints.xs.width}) {
    display: flex;
    justify-content: space-between;
    ${({ theme, withPadding }) => withPadding && `padding: ${theme.indent.m} 0;`}
  }
`;
