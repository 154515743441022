import {
  ORDER_REQUEST,
  ORDER_INIT_REQUEST,
  ORDER_TICKETS_REQUEST,
  SET_ORDER,
  CLEAR_ORDER,
  ORDER_CANCEL_REQUEST
} from './types';

export const orderRequest = (cinemaId, sessionId, tickets) => ({
  type: ORDER_REQUEST,
  payload: {
    cinemaId,
    sessionId,
    tickets
  }
});
export const orderInitRequest = (cinemaId, sessionId, tickets) => ({
  type: ORDER_INIT_REQUEST,
  payload: {
    cinemaId,
    sessionId,
    tickets
  }
});
export const orderTicketsRequest = (sessionId, tickets, userSessionId) => ({
  type: ORDER_TICKETS_REQUEST,
  payload: {
    sessionId,
    tickets,
    userSessionId
  }
});

export const setOrder = order => ({
  type: SET_ORDER,
  payload: {
    order
  }
});
export const clearOrder = () => ({
  type: CLEAR_ORDER
});

export const orderCancelRequest = orderId => ({
  type: ORDER_CANCEL_REQUEST,
  payload: orderId
});
