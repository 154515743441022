import * as deepmerge from 'deepmerge';
import common from './common';

const apiEndpoint = process.env.REACT_APP_API_ENDPOINT;
const country = process.env.REACT_APP_COUNTRY;
const googleAnalyticKey = process.env.REACT_APP_GOOGLE_ANALYTIC_KEY;
const googleMapKey = process.env.REACT_APP_GOOGLE_MAP_KEY;
const env = process.env.REACT_APP_ENV;

// eslint-disable-next-line
const required = require(`./countries/${country}`).default;
const commonConfig = common(apiEndpoint, googleAnalyticKey, googleMapKey);
const requiredConfig = required();
const arrayMerge = (destinationArray, sourceArray) => sourceArray;

const config = deepmerge(commonConfig, requiredConfig, { arrayMerge });

if (env === 'development') {
  config.intl = commonConfig.intl;
}

export default config;
