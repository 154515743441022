import styled from 'styled-components';
import { NavLink } from 'react-router-dom';

export const BlockMain = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-left: 64px;
  ${({ isVisible }) => !isVisible && 'display: none;'}
  
  @media screen and (max-width: ${({ theme }) => theme.breakPoints.md.width}) {
    justify-content: flex-end;
    margin-left: 0;
  }
`;

export const HeaderTag = styled.header.attrs({
  className: 'header'
})`
  width: 100%;
  min-width: 320px;
  height: ${({ theme }) => theme.breakPoints.md.header};
  position: fixed;
  z-index: 10;
  left: 0;
  top: 0;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
  background-color: ${({ theme }) => theme.color.bgSecondary};
  font-size: 0;

  & > .container {
    display: flex;
    align-items: center;
    height: 100%;
    position: relative;
  }

  & + .footer {
    margin-top: auto;
  }

  @media screen and (max-width: ${({ theme }) => theme.breakPoints.md.width}) {
    height: ${({ theme }) => theme.breakPoints.sm.header};
  }
`;

export const LogoLink = styled(NavLink)`
  & > .svg-logo {
    width: 100px;
    height: 44px;
  }

  & > .svg-logo-md {
    width: 90px;
    height: 20px;
    display: none;
  }

  @media screen and (max-width: ${({ theme }) => theme.breakPoints.md.width}) {
    margin: 0 auto;

    & > .svg-logo {
      display: none;
    }

    & > .svg-logo-md {
      display: block;
    }
  }
`;
