import BaseAPI from '../BaseAPI';

const registrationSchema = {
  birth: 'dateOfBirth',
  country: 'workZipCode',
  address: 'address1',
  zip: 'zipCode',
  phone: 'mobilePhone',
  favorites: 'preferredComplexList'
};

const resetPasswordSchema = {
  password: 'newPassword',
  passwordRepeat: 'confirmPassword',
  code: 'resetCode'
};

/**
 * Transforms server error response in case of form errors
 *
 * @param errors
 * @return {object}
 */
const transformErrors = errors => {
  const report = {};

  Object.keys(errors).forEach(key => {
    if (!Object.prototype.hasOwnProperty.call(errors[key], 'errors')) {
      return;
    }

    report[key] = errors[key].errors;
  });

  return report;
};

/**
 * Returns date in YYYY-MM-DD format

 * @param date {Date}
 * @returns {string}
 */
const getFormattedDate = date => {
  const pad2 = n => (n < 10 ? '0' : '') + n;
  const y = date.getFullYear();
  const month = pad2(date.getMonth() + 1);
  const day = pad2(date.getDate());

  return [y, month, day].join('-');
};

/**
 * API Class for authorization
 */
class Auth extends BaseAPI {
  /**
   * Creates Auth instance
   *
   * @extends BaseAPI
   * @param props {object}
   * @param workZipCode {string}
   */
  constructor({ user: { workZipCode }, ...props }) {
    super(props);

    this.workZipCode = workZipCode;
    this.baseUrl = '/users';
  }

  /**
   * Makes user registration
   *
   * @param data {object}
   * @param data.firstName {string}
   * @param data.lastName {string}
   * @param data.email {string}
   * @param data.password {string}
   * @param data.gender {string}
   * @param data.birth {date}
   * @param data.favorites {string[]} - favorite cinemas
   * @param data.sendNewsletter {boolean}
   * @param data.phone {string}
   * @param data.country {string | undefined}
   * @param data.city {string | undefined}
   * @param data.address {string | undefined}
   * @param data.zip {string | undefined}
   * @returns {Promise<*|Error>}
   */
  register(data) {
    const url = this.baseUrl;
    const body = BaseAPI.mapSchemaWithData(registrationSchema, {
      ...data,
      country: this.workZipCode,
      birth: getFormattedDate(data.birth)
    });

    return this.post({ url, body }).catch(err => {
      if (err.errors) {
        err.errors = BaseAPI.mapSchemaWithData(
          BaseAPI.invertSchema(registrationSchema),
          transformErrors(err.errors.children.loyaltyMember.children)
        );
      }

      throw err;
    });
  }

  /**
   * Makes login
   *
   * @param data {object}
   * @param data.email {string}
   * @param data.password {string}
   * @returns {Promise<*|Error>} - user
   */
  login(data) {
    const url = `${this.baseUrl}/auth`;

    return this.post({ url, body: data }).then(({ token, user }) => {
      const accessToken = token.token;
      const refreshToken = token.refresh_token;

      this.setAccessToken(accessToken);
      this.setRefreshToken(refreshToken);

      return BaseAPI.mapSchemaWithData(BaseAPI.invertSchema(registrationSchema), user);
    });
  }

  /**
   * Makes logout without network request
   *
   * @returns {Promise<*>}
   */
  logout() {
    this.removeAccessToken();
    this.removeRefreshToken();

    return Promise.resolve();
  }

  /**
   * Updates access token when it expires
   *
   * @returns {Promise<*>} - user
   */
  updateToken() {
    const url = `${this.baseUrl}/auth/refresh-token`;
    const body = {
      refresh_token: this.getRefreshToken()
    };

    return this.post({ url, body }).then(({ token, user }) => {
      const accessToken = token.token;
      const refreshToken = token.refresh_token;

      this.setAccessToken(accessToken);
      this.setRefreshToken(refreshToken);

      return BaseAPI.mapSchemaWithData(BaseAPI.invertSchema(registrationSchema), user);
    });
  }

  /**
   * Makes user activation
   *
   * @param token {string}
   * @param memberId {string}
   * @return {Promise<*>}
   */
  activate(token, memberId) {
    const url = `${this.baseUrl}/activate`;
    const body = {
      token,
      memberId
    };

    return this.post({ url, body });
  }

  /**
   * Sends email for resetting password
   *
   * @param data {object}
   * @param data.email {string}
   * @returns {Promise<*|Error>}
   */
  forgotPassword(data) {
    const url = `${this.baseUrl}/reset-password`;

    return this.post({ url, body: data }).catch(err => {
      try {
        err.errors = transformErrors(err.errors.children);
      } catch (e) {
        throw err;
      }

      throw err;
    });
  }

  /**
   * Resets password
   *
   * @param data
   * @param data.password {string}
   * @param data.repeatPassword {string}
   * @param data.memberId {string}
   * @param data.code {string}
   * @return {Promise<*|Error>}
   */
  resetPassword(data) {
    const url = `${this.baseUrl}/change-password-reset-code`;
    const body = BaseAPI.mapSchemaWithData(resetPasswordSchema, data);

    return this.post({ url, body });
  }

  /**
   * Updates user info
   *
   * @param data {object}
   * @param data.firstName {string}
   * @param data.lastName {string}
   * @param data.gender {string}
   * @param data.birth {date}
   * @param data.phone {string}
   * @param data.country {string | undefined}
   * @param data.city {string | undefined}
   * @param data.address {string | undefined}
   * @param data.zip {string | undefined}
   * @return {Promise<*|Error>}
   */
  async updateUser(data) {
    const birth = data.birth ? getFormattedDate(data.birth) : undefined;
    const url = this.baseUrl;
    const body = BaseAPI.mapSchemaWithData(registrationSchema, {
      ...data,
      birth
    });

    try {
      const user = await this.patch({ url, body });

      return BaseAPI.mapSchemaWithData(BaseAPI.invertSchema(registrationSchema), user);
    } catch (err) {
      if (err.errors) {
        const parsedErrors = BaseAPI.mapSchemaWithData(
          BaseAPI.invertSchema(registrationSchema),
          transformErrors(err.errors.children.loyaltyMember.children)
        );

        err.errors = Object.keys(parsedErrors).length ? parsedErrors : err.errors.errors;
      }

      throw err;
    }
  }

  /**
   * Changes user password
   * @param data
   * @param data.confirmPassword {string}
   * @param data.newPassword {string}
   * @param data.password {string}
   * @return {Promise<*|Error>}
   */
  async changePassword(data) {
    const version = '/api/v2';
    const url = `${this.baseUrl}/change-password`;

    try {
      const user = await this.post({ url, body: data, version });

      return BaseAPI.mapSchemaWithData(BaseAPI.invertSchema(registrationSchema), user);
    } catch (err) {
      if (err.errors) {
        err.errors = transformErrors(err.errors.children);
      }

      throw err;
    }
  }
}

export default Auth;
