import Cinemas from './modules/Cinemas';
import Events from './modules/Events';
import Information from './modules/Information';
import Movies from './modules/Movies';
import Auth from './modules/Auth';
import User from './modules/User';
import Sessions from './modules/Sessions';
import Orders from './modules/Orders';
import Payment from './modules/Payment';
import Bookings from './modules/Bookings';
import Barcode from './modules/Barcode';
import PDF from './modules/PDF';
import SeatPlan from './modules/SeatPlan';
import Watchlist from './modules/Watchlist';

class API {
  constructor(config, storage) {
    const { api, headerTypes } = config;
    const options = { ...api, storage, headerTypes };

    this.modules = {
      cinemas: new Cinemas(options),
      events: new Events(options),
      information: new Information(options),
      movies: new Movies(options),
      auth: new Auth(options),
      user: new User(options),
      sessions: new Sessions(options),
      orders: new Orders(options),
      payment: new Payment(options),
      bookings: new Bookings(options),
      barcode: new Barcode(options),
      pdf: new PDF(options),
      seatPlan: new SeatPlan(options),
      watchlist: new Watchlist(options)
    };
  }

  getModuleByName(name) {
    if (Object.keys(this.modules).indexOf(name) < 0) {
      throw new Error("API class doesn't contain module with specified name");
    }

    return this.modules[name];
  }
}

export default API;
