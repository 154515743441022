const ROUTE = 'route';
const ORDER = 'order';
const PAYMENT = 'payment';
const BOOKING_DETAILS = 'booking_details';
const WIZARD_SELECTION = 'wizard_selection';
const HISTORY_COUNT = 'history_count';
const FIRST_TIME = 'gotofixedevent';

class Session {
  constructor() {
    this.session = sessionStorage;
  }

  setRoute(val) {
    this.session.setItem(ROUTE, val);
  }

  getRoute() {
    return this.session.getItem(ROUTE);
  }

  removeRoute() {
    this.session.removeItem(ROUTE);
  }

  setOrder(val) {
    this.session.setItem(ORDER, JSON.stringify(val));
  }

  getOrder() {
    return JSON.parse(this.session.getItem(ORDER));
  }

  removeOrder() {
    this.session.removeItem(ORDER);
  }

  setPayment(start, orderPaymentTTL, pingTimeoutSecs, pingTimes) {
    this.session.setItem(
      PAYMENT,
      JSON.stringify({
        start,
        orderPaymentTTL,
        pingTimeoutSecs,
        pingTimes
      })
    );
  }

  getPayment() {
    return JSON.parse(this.session.getItem(PAYMENT));
  }

  removePayment() {
    this.session.removeItem(PAYMENT);
  }

  setBookingDetails(isRedemption) {
    this.session.setItem(BOOKING_DETAILS, JSON.stringify({ isRedemption }));
  }

  getBookingDetails() {
    return JSON.parse(this.session.getItem(BOOKING_DETAILS));
  }

  removeBookingDetails() {
    this.session.removeItem(BOOKING_DETAILS);
  }

  setWizardSelection(wizard) {
    this.session.setItem(WIZARD_SELECTION, JSON.stringify(wizard));
  }

  getWizardSelection() {
    return JSON.parse(this.session.getItem(WIZARD_SELECTION));
  }

  removeWizardSelection() {
    this.session.removeItem(WIZARD_SELECTION);
  }

  getHistoryCount() {
    return this.session.getItem(HISTORY_COUNT);
  }

  setHistoryCount(val) {
    this.session.setItem(HISTORY_COUNT, val);
  }

  removeHistoryCount() {
    this.session.removeItem(HISTORY_COUNT);
  }

  getGoToFixedEvent() {
    const retVal = this.session.getItem(FIRST_TIME) == null;

    this.setGoToFixedEvent(false);
    
return retVal;
  }

  setGoToFixedEvent(value) {
    this.session.setItem(FIRST_TIME, value);
  }

  clear() {
    this.session.clear();
  }
}

export default new Session();
