import React from 'react';
import PropTypes from 'prop-types';
import ReactModal from 'react-modal';
import useTheme from 'app/hooks/useTheme';
import Svg from 'app/components/Svg';
import { CloseBtn, ContentWrapper } from './styles';

const propTypes = {
  close: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  children: PropTypes.element.isRequired
};

const Modal = ({ close, isOpen, children }) => {
  const theme = useTheme();
  const styles = {
    overlay: {
      zIndex: 12,
      overflowY: 'auto',
      backgroundColor: theme.color.rgbaBlack(0.5),
      textAlign: 'center'
    },
    content: {
      display: 'inline-block',
      verticalAlign: 'middle',
      width: '100%',
      maxWidth: 952,
      minWidth: 320,
      margin: '30px 0',
      padding: 0,
      position: 'relative',
      top: 'auto',
      left: 'auto',
      right: 'auto',
      bottom: 'auto',
      border: 0,
      borderRadius: 0,
      boxShadow: `0 3px 16px 0 ${theme.color.rgbaBlack(0.5)}`,
      backgroundColor: theme.color.bgPopup,
      fontSize: 0
    }
  };

  return (
    <ReactModal isOpen={isOpen} style={styles} onRequestClose={close}>
      <CloseBtn onClick={close}>
        <Svg id="close" />
      </CloseBtn>

      <ContentWrapper>{children}</ContentWrapper>
    </ReactModal>
  );
};

Modal.propTypes = propTypes;
export default React.memo(Modal);
