import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Svg } from 'app/components';
import useConfig from 'app/hooks/useConfig';
import { onHoverBehavior, onClickBehavior } from 'app/utils';
import LoginButton from './LoginButton';
import SignupButton from './SignupButton';
import UserDropdown from './UserDropdown';
import { SearchLink, BlockControlWrapper, LanguageSwitcher, SubList, SubListItem } from './styles';

const propTypes = {
  isLoggedIn: PropTypes.bool.isRequired,
  userName: PropTypes.string
};
const defaultProps = {
  userName: null
};

const BlockControl = ({ isLoggedIn, userName }) => {
  const [, i18n] = useTranslation();
  const {
    routes,
    intl: { locales }
  } = useConfig();
  const selectedLang = i18n.language;
  const changeLanguage = locale => () => {
    i18n.changeLanguage(locale);
    window.location.reload();
  };

  return (
    <BlockControlWrapper>
      <SearchLink to={routes.search.pattern} activeClassName="_active">
        <Svg id="search" />
      </SearchLink>

      {!isLoggedIn && <LoginButton />}

      {locales.length > 1 ? (
        <LanguageSwitcher chevron {...onHoverBehavior}>
          <Svg id="language" />
          <span className="_with-chevron">{selectedLang}</span>

          <SubList>
            {locales.map(loc => (
              <SubListItem key={loc.locale} {...onClickBehavior}>
                <button
                  type="button"
                  onClick={changeLanguage(loc.locale)}
                  className={`l-sublist__link${selectedLang === loc.locale ? ' _active' : ''}`}
                >
                  <span>{loc.label}</span>
                  {selectedLang === loc.locale && <Svg id="checkmark" />}
                </button>
              </SubListItem>
            ))}
          </SubList>
        </LanguageSwitcher>
      ) : null}

      {isLoggedIn ? <UserDropdown userName={userName} /> : <SignupButton />}
    </BlockControlWrapper>
  );
};

BlockControl.propTypes = propTypes;
BlockControl.defaultProps = defaultProps;
export default BlockControl;
