import React from 'react';
import PropTypes from 'prop-types';
import { NavLink, generatePath } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import useConfig from 'app/hooks/useConfig';
import useUrlParams from 'app/hooks/useUrlParams';
import { Container, Svg } from 'app/components';
import { BlockMainWrapper, BlockMainLinks, ListLinks, ListLinksItem } from './styles';

const propTypes = {
  locations: PropTypes.arrayOf(PropTypes.object).isRequired,
  savedLocation: PropTypes.string.isRequired
};

const moviesToBase = routes => (category, date, location) => ({
  pathname: routes.movies.pattern,
  search: `?category=${category}${location ? `&location=${location}` : ''}${
    date ? `&date=${date}` : ''
  }`
});

const BlockMain = ({ locations, savedLocation }) => {
  const [t] = useTranslation();
  const { routes, companyName, footer } = useConfig();
  const { date } = useUrlParams('date');
  const moviesTo = moviesToBase(routes);
  const isLinkActive = searchParam => (match, params) =>
    `${params.pathname}${params.search}`.indexOf(searchParam) >= 0;

  return (
    <BlockMainWrapper>
      <Container>
        <BlockMainLinks>
          <div className="footer__main-inner">
            <h5 className="footer__main-links-title">{t('common.movies')}</h5>
            <ListLinks>
              <ListLinksItem>
                <NavLink
                  to={moviesTo('top', date, savedLocation)}
                  isActive={isLinkActive('top')}
                  className="l-footer-main__link"
                  activeClassName="_active"
                >
                  {t('common.top_movies')}
                </NavLink>
              </ListLinksItem>
              <ListLinksItem>
                <NavLink
                  to={moviesTo('now', date, savedLocation)}
                  isActive={isLinkActive('now')}
                  className="l-footer-main__link"
                  activeClassName="_active"
                >
                  {t('common.now_playing')}
                </NavLink>
              </ListLinksItem>
              <ListLinksItem>
                <NavLink
                  to={moviesTo('upcoming', 'all')}
                  isActive={isLinkActive('upcoming')}
                  className="l-footer-main__link"
                  activeClassName="_active"
                >
                  {t('common.upcoming')}
                </NavLink>
              </ListLinksItem>
            </ListLinks>
          </div>

          <div className="footer__main-inner">
            <h5 className="footer__main-links-title">{t('common.cinemas')}</h5>
            <ListLinks>
              {locations.length ? (
                locations.map(({ id, name }) => (
                  <ListLinksItem key={name}>
                    <NavLink
                      to={{
                        pathname: generatePath(routes.cinemas.pattern),
                        search: `?location=${id}${date ? `&date=${date}` : ''}`
                      }}
                      isActive={() => id.toString() === savedLocation}
                      activeClassName="_active"
                      className="l-footer-main__link"
                      exact
                    >
                      {name}
                    </NavLink>
                  </ListLinksItem>
                ))
              ) : (
                <ListLinksItem>{t('cinemas.no_cinemas')}</ListLinksItem>
              )}
            </ListLinks>
          </div>

          <div className="footer__main-inner _store-links">
            <h5 className="footer__main-links-title">{`${companyName} apps`}</h5>
            <ListLinks>
              <ListLinksItem withSvg>
                <a
                  href={footer.apps.ios}
                  target="_blank"
                  className="l-footer-main__link"
                  rel="noopener noreferrer"
                >
                  <Svg id="appstore" />
                </a>
              </ListLinksItem>
              <ListLinksItem withSvg>
                <a
                  href={footer.apps.android}
                  target="_blank"
                  className="l-footer-main__link"
                  rel="noopener noreferrer"
                >
                  <Svg id="playstore" />
                </a>
              </ListLinksItem>
            </ListLinks>
          </div>
        </BlockMainLinks>
      </Container>
    </BlockMainWrapper>
  );
};

BlockMain.propTypes = propTypes;
export default BlockMain;
