import i18n from 'i18next';
import ICU from 'i18next-icu';
import Backend from 'i18next-xhr-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

export default function({ fallbackLng, locales }) {
  /* eslint-disable */
  const loadedLocales = locales.map(({ locale }) => require(`i18next-icu/locale-data/${locale}`));
  /* eslint-enable */

  const icu = new ICU({
    localeData: loadedLocales,
    formats: {
      number: {
        BAM: {
          style: 'decimal',          
          minimumIntegerDigits: 1,
          minimumFractionDigits: 2,
          maximumFractionDigits: 2          
        },
        EUR: {
          style: 'currency',
          currency: 'EUR'
        }
      },
      date: {
        LONG: {
          weekday: 'long',
          year: 'numeric',
          month: 'long',
          day: 'numeric'
        },
        DMY: {
          year: 'numeric',
          month: 'long',
          day: 'numeric'
        },
        LONG_DAY: {
          weekday: 'long'
        },
        SHORT_MONTH: {
          month: 'short'
        },
        LONG_MONTH: {
          month: 'long'
        },
        DMY_NUMERIC: {
          year: 'numeric',
          month: 'numeric',
          day: 'numeric'
        }
      },

      a: {
        LONG: {
          weekday: 'long'
        },
        DMY: {
          day: 'long'
        }
      }
    }
  });

  i18n
    .use(icu)
    .use(Backend)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
      fallbackLng,
      whitelist: locales.map(loc => loc.locale),
      load: 'unspecific',
      debug: true,

      interpolation: {
        escapeValue: false
      }
    });
}
