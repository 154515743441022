import { from, of } from 'rxjs';
import { mergeMap, concatMap, catchError, startWith } from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { setLoader, createToast, handleErrors } from './deps';
import {
  setLegalInfoContent,
  setImpressumContent,
  setB2bContent,
  setPriceListContent
} from './actions';
import {
  INFORMATION_LEGAL_REQUEST,
  INFORMATION_IMPRESSUM_REQUEST,
  INFORMATION_B2B_REQUEST,
  INFORMATION_PRICELIST_REQUEST
} from './types';

export const fetchLegalInfo = ($action, $state, { api }) => {
  const $apiInformation = api.getModuleByName('information');

  return $action.pipe(
    ofType(INFORMATION_LEGAL_REQUEST),
    mergeMap(action =>
      from($apiInformation.fetchLegalInfo()).pipe(
        concatMap(res => [setLegalInfoContent(res), setLoader(false)]),

        ...handleErrors(action),
        catchError(err =>
          of(createToast('warning', err.message.replace(':', '')), setLoader(false))
        ),

        startWith(setLoader(true))
      )
    )
  );
};

export const fetchImpressum = ($action, $state, { api }) => {
  const $api = api.getModuleByName('information');

  return $action.pipe(
    ofType(INFORMATION_IMPRESSUM_REQUEST),
    mergeMap(action =>
      from($api.fetchImpressum()).pipe(
        concatMap(res => [setImpressumContent(res), setLoader(false)]),

        ...handleErrors(action),
        catchError(err =>
          of(createToast('warning', err.message.replace(':', '')), setLoader(false))
        ),

        startWith(setLoader(true))
      )
    )
  );
};

export const fetchB2b = ($action, $state, { api }) => {
  const $api = api.getModuleByName('information');

  return $action.pipe(
    ofType(INFORMATION_B2B_REQUEST),
    mergeMap(action =>
      from($api.fetchB2b()).pipe(
        concatMap(res => [setB2bContent(res), setLoader(false)]),

        ...handleErrors(action),
        catchError(err =>
          of(createToast('warning', err.message.replace(':', '')), setLoader(false))
        ),

        startWith(setLoader(true))
      )
    )
  );
};

export const fetchPriceList = ($action, $state, { api }) => {
  const $api = api.getModuleByName('cinemas');

  return $action.pipe(
    ofType(INFORMATION_PRICELIST_REQUEST),
    mergeMap(action => {
      const { id } = action.payload;

      return from($api.fetchPriceList(id)).pipe(
        concatMap(res => [setPriceListContent(res), setLoader(false)]),

        ...handleErrors(action),
        catchError(err =>
          of(createToast('warning', err.message.replace(':', '')), setLoader(false))
        ),

        startWith(setLoader(true))
      );
    })
  );
};
