import {
  MOVIES_FETCH_SUCCESS,
  SET_UPCOMING_MOVIES,
  MOVIES_SET_DATE,
  MOVIES_RESET_STATE,
  SET_DAYS_SESSIONS,
  SET_MOVIE,
  SET_DATE_LIST,
  SET_SESSIONS
} from './types';

const initialState = {
  films: [],
  movieDetails: {},
  sessions: [],
  datesDetails: [],
  dates: [],
  date: null,
  upcomingDates: [],
  daysSessions: []
};

export default function(state = initialState, { type, payload }) {
  switch (type) {
    case MOVIES_FETCH_SUCCESS: {
      return {
        ...state,
        films: payload.films || [],
        dates: payload.dates || []
      };
    }

    case SET_UPCOMING_MOVIES: {
      return {
        ...state,
        films: payload.films || [],
        upcomingDates: payload.upcomingDates || []
      };
    }

    case MOVIES_SET_DATE: {
      return {
        ...state,
        date: payload.date
      };
    }

    case MOVIES_RESET_STATE: {
      return {
        ...state,
        films: [],
        dates: [],
        upcomingDates: []
      };
    }

    case SET_DAYS_SESSIONS: {
      return {
        ...state,
        daysSessions: payload.sessions || []
      };
    }

    case SET_MOVIE: {
      return {
        ...state,
        movieDetails: payload.movie
      };
    }

    case SET_DATE_LIST: {
      return {
        ...state,
        datesDetails: payload.dates
      };
    }

    case SET_SESSIONS: {
      return {
        ...state,
        sessions: payload.sessions
      };
    }

    default: {
      return state;
    }
  }
}
