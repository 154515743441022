import { map, mergeMap, catchError, concatMap, startWith } from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { of, from } from 'rxjs';
import { orderInitRequest, orderTicketsRequest, setOrder, clearOrder } from './actions';

import {
  ORDER_INIT_REQUEST,
  ORDER_TICKETS_REQUEST,
  ORDER_REQUEST,
  ORDER_CANCEL_REQUEST
} from './types';
import { createToast, handleErrors, setLoader, setErrorApi, setSeatPlan } from './deps';

/* TODO: move implementation of order initialization to backend */
export const makeOrder = ($action, $state) => {
  return $action.pipe(
    ofType(ORDER_REQUEST),
    map(({ payload }) => {
      const { order: isOrderCreated } = $state.value;
      const { cinemaId, sessionId, tickets } = payload;

      return !isOrderCreated
        ? orderInitRequest(cinemaId, sessionId, tickets)
        : orderTicketsRequest(sessionId, tickets);
    })
  );
};

export const initOrder = ($action, $state, { api }) => {
  const $api = api.getModuleByName('orders');

  return $action.pipe(
    ofType(ORDER_INIT_REQUEST),
    mergeMap(action => {
      const { cinemaId, sessionId, tickets } = action.payload;

      return from($api.initOrder(cinemaId)).pipe(
        concatMap(({ userSessionId }) => [
          orderTicketsRequest(sessionId, tickets, userSessionId),
          setLoader(false)
        ]),

        ...handleErrors(action),
        catchError(err =>
          of(createToast('warning', err.message.replace(':', '')), setLoader(false))
        ),
        startWith(setLoader(true))
      );
    })
  );
};

export const orderTickets = ($action, $state, { api }) => {
  const $api = api.getModuleByName('orders');
  const expectedErrors = {
    PAYMENT: {
      type: 'PAYMENT',
      code: 1
    },
    SEATPLAN: {
      type: 'SEATPLAN',
      code: 4
    }
  };

  return $action.pipe(
    ofType(ORDER_TICKETS_REQUEST),
    mergeMap(action => {
      const { sessionId, tickets, userSessionId } = action.payload;
      const {
        sessions: { wizard }
      } = $state.value;

      const orderId = userSessionId || $state.value.order.userSessionId;

      return from($api.orderTickets(orderId, sessionId, tickets, wizard)).pipe(
        concatMap(({ seatPlan, order, errorMessage }) => {
          const actions = [setSeatPlan(seatPlan), setOrder(order), setLoader(false)];

          if (errorMessage) {
            return [createToast('warning', errorMessage), setLoader(false)];
          }

          return actions;
        }),

        ...handleErrors(action),
        catchError(err => {
          const actions = [createToast('warning', err.message.replace(':', '')), setLoader(false)];

          if (Object.keys(expectedErrors).indexOf(err.type) > -1) {
            actions.push(
              setErrorApi({
                ...err,
                expectedErrors
              })
            );
          }

          return of(...actions);
        }),

        startWith(setLoader(true))
      );
    })
  );
};

export const cancelOrder = ($action, $state, { api }) => {
  const $api = api.getModuleByName('orders');

  return $action.pipe(
    ofType(ORDER_CANCEL_REQUEST),
    mergeMap(action => {
      const { payload } = action;

      return from($api.cancelOrder(payload)).pipe(map(clearOrder));
    })
  );
};
