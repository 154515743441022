import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import withRouteEvents from 'app/HOC/withRouteEvents';
import storage from 'app/services/storage';
import authModule from 'app/store/auth';
import cinemasModule from 'app/store/cinemas';
import layoutModule from 'app/store/layout';
import informationModule from 'app/store/information';
import Layout from './Layout.component';

const { requestCinemas } = cinemasModule.actions;
const { requestLocations } = layoutModule.actions;
const { refreshTokenRequest } = authModule.actions;
const { legalInfoContentRequest } = informationModule.actions;

const mapStateToProps = ({ layout, user, cinemas, information: { legalInfoContent } }) => ({
  isLoggedIn: !!user.user,
  userName: user.user ? user.user.firstName : null,
  token: storage.getRefreshToken(),
  isLoading: layout.isLoading,
  error: layout.error,
  cinemas: cinemas.cinemas,
  locations: layout.locations,
  savedLocation: storage.getLocation(),
  elementsVisibility: layout.elementsVisibility,

  saveLocation: location => storage.setLocation(location),
  acceptCookiesAgreement: () => {
    storage.acceptCookiesAgreement();
    window.location.reload();
  },
  declineCookiesAgreement: () => storage.declineCookiesAgreement(),
  isRespondedToCookiesAgreement: !!storage.getCookiesAgreementStatus(),
  legalInfo: legalInfoContent
});

const mapDispatchToProps = {
  refreshToken: refreshTokenRequest,
  fetchCinemas: requestCinemas,
  getLocations: requestLocations,
  getLegalInfo: legalInfoContentRequest
};

export default compose(
  withRouter,
  withRouteEvents,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(Layout);
