import { createMuiTheme } from '@material-ui/core';
import theme from 'app/theme';

export default createMuiTheme({
  typography: {
    useNextVariants: true
  },

  overrides: {
    MuiFormControl: {
      root: {
        display: 'none'
      }
    },

    MuiPickersToolbar: {
      toolbar: {
        height: '95px',
        backgroundColor: theme.color.bgSecondary
      }
    },

    MuiPickersCalendarHeader: {
      dayLabel: {
        width: '39px',
        fontSize: '1.2rem',
        fontWeight: '500',
        color: theme.color.rgbaWhite('.38')
      },

      switchHeader: {
        margin: '0',
        padding: '3px 5px 5px'
      },

      iconButton: {
        backgroundColor: 'transparent',

        '&:hover': {
          backgroundColor: 'transparent'
        },

        '&:hover svg': {
          fill: theme.color.rgbaWhite('1')
        },

        '& > span': {
          backgroundColor: 'transparent'
        }
      }
    },

    MuiSvgIcon: {
      root: {
        fill: theme.color.rgbaWhite('.4')
      }
    },

    MuiPickersDay: {
      day: {
        width: '40px',
        height: '40px',
        color: theme.color.rgbaWhite('.87'),
        fontSize: '1.2rem',
        '&:hover': {
          backgroundColor: theme.color.accent
        }
      },

      isSelected: {
        backgroundColor: theme.color.accentDark,
        pointerEvents: 'none'
      },

      isDisabled: {
        color: theme.color.rgbaWhite('.2')
      },

      current: {
        color: theme.color.textPrimary,
        backgroundColor: theme.color.rgbaAccent('.3')
      }
    },

    MuiButton: {
      textPrimary: {
        textTransform: 'uppercase',
        fontSize: '1.4rem',
        fontWeight: '700',
        color: theme.color.textPrimary,
        borderRadius: '20px',

        '&:hover': {
          backgroundColor: theme.color.bgSecondary
        },

        '&:first-child': {
          marginRight: '10px'
        }
      }
    },

    MuiPickersToolbarButton: {
      toolbarBtnSelected: {
        color: theme.color.textPrimary,
        fontSize: '3.4rem',
        fontWeight: '500'
      }
    },

    MuiPickersModal: {
      dialogRoot: {
        minWidth: '328px',
        minHeight: '470px',
        boxShadow: '0 15px 12px 0 rgba(0, 0, 0, 0.22)',
        backgroundColor: theme.color.bgPopup,
        color: theme.color.textPrimary,

        '@media screen and (max-width: 740px)': {
          minWidth: '300px',
          minHeight: '465px'
        }
      }
    },

    MuiPickersMonth: {
      root: {
        transition: 'color .2s ease-out',

        '&:focus': {
          color: theme.color.textPrimary
        },

        '&:hover': {
          color: theme.color.accent
        },
        '&$selected': {
          color: theme.color.accentDark
        },
        '&$disabled': {
          color: theme.color.rgbaWhite('.2')
        }
      }
    },

    MuiPickersYear: {
      root: {
        transition: 'color .2s ease-out',

        '&$selected': {
          margin: '5px 0',
          color: theme.color.accentDark,
          fontWeight: '700',
          fontSize: '2.3rem'
        },

        '&:hover': {
          color: theme.color.accent
        },

        '&:focus': {
          color: theme.color.accent
        }
      }
    },

    MuiDialogActions: {
      root: {
        margin: '0',
        padding: '8px 12px 15px'
      }
    },

    MuiDialog: {
      container: {
        overflowY: 'auto',
        padding: '25px 0'
      },

      paper: {
        margin: 'auto 0'
      }
    },

    MuiTypography: {
      alignCenter: {
        color: theme.color.textPrimary,
        fontSize: '1.4rem'
      },

      subtitle1: {
        color: theme.color.rgbaWhite('.7'),
        fontSize: '1.6rem',
        fontWeight: '500'
      }
    }
  }
});
