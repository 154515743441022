import BaseAPI from '../BaseAPI';

class Information extends BaseAPI {
  constructor(props) {
    super(props);

    this.baseUrl = '/information';
  }

  /**
   * Gets an info for the page "Legal information"
   *
   * @returns {Promise<*|Error>}
   */
  fetchLegalInfo() {
    const url = `${this.baseUrl}/legal-info`;
    const enums = {
      1: 'processingOfPersonalData',
      2: 'termsAndConditions',
      3: 'cookiePolicy'
    };

    return this.get({ url }).then(res =>
      res.map(info => ({
        ...info,
        type: enums[info.docType]
      }))
    );
  }

  /**
   * Gets an info for the page Impressum
   *
   * @returns {Promise<*|Error>}
   */
  fetchImpressum() {
    const url = `${this.baseUrl}/impressum`;
    const version = '';
    const get = this.withHTML(this.get);

    return get({ url, version });
  }

  /**
   * Gets an info for the page B2B
   *
   * @returns {Promise<*|Error>}
   */
  fetchB2b() {
    const url = `${this.baseUrl}/b2b`;
    const version = '';
    const get = this.withHTML(this.get);

    return get({ url, version });
  }
}

export default Information;
