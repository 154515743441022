export const BUY_REQUEST = 'payment/BUY_REQUEST';
export const BUY_SUCCESS = 'payment/BUY_SUCCESS';

export const RESERVE_REQUEST = 'payment/RESERVE_REQUEST';

export const PURCHASE_REQUEST = 'payment/PURCHASE_REQUEST';
export const PURCHASE_SUCCESS = 'payment/PURCHASE_SUCCESS';

export const PURCHASE_STATUS_REQUEST = 'payment/PURCHASE_STATUS_REQUEST';

export const PAYMENT_STATUS_REQUEST = 'payment/PAYMENT_STATUS_REQUEST';
export const SET_PAYMENT_STATUS = 'payment/SET_PAYMENT_STATUS';
