/* eslint-disable no-restricted-syntax */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';
import { Select, Svg } from 'app/components';
import { dateValidator, getFormattedDate } from 'app/utils';
import useUtils from 'app/hooks/useUtils';
import FilterWrapper from './styles';

const propTypes = {
  dates: PropTypes.arrayOf(PropTypes.string).isRequired,
  selectedDate: PropTypes.string,
  onSelectDate: PropTypes.func.isRequired,
  redLabelDate: PropTypes.bool,

  locations: PropTypes.arrayOf(PropTypes.object),
  selectedLocation: PropTypes.string,
  onSelectLocation: PropTypes.func,
  redLabelLocation: PropTypes.bool,

  isUpcoming: PropTypes.bool,
  isAsMonths: PropTypes.bool,
  withPadding: PropTypes.bool
};

const defaultProps = {
  selectedDate: null,
  redLabelDate: false,

  locations: null,
  selectedLocation: null,
  onSelectLocation: null,
  redLabelLocation: false,

  isUpcoming: false,
  isAsMonths: false,
  withPadding: true
};

const MoviesDatesFilter = ({
  dates,
  selectedDate,
  onSelectDate,
  redLabelDate,

  locations,
  selectedLocation,
  redLabelLocation,
  onSelectLocation,

  isUpcoming,
  isAsMonths,
  withPadding
}) => {
  const [t] = useTranslation();
  const isDateValid = (isAsMonths && selectedDate === 'all') || dateValidator(selectedDate);
  const [datesForDropdown, setDates] = useState([]);
  const [selectedDateValue, setSelectedDate] = useState(null);
  const [locationsForDropdown, setLocations] = useState([]);
  const [selectedLocationValue, setSelectedLocation] = useState(null);
  const { getDateWithOffset } = useUtils();

  useEffect(() => {
    if (!isDateValid) return;

    // LIST OF OPTIONS WITH THE YEAR

    const years = [];

    for (const date of dates) {
      if (years.length === 0) {
        years.push(new Date(date).getFullYear());
      } else if (!years.find(e => e === new Date(date).getFullYear())) {
        years.push(new Date(date).getFullYear());
      }
    }
    let options = [];

    for (const year of years) {
      if (year) {
        options.push({ label: year.toString(), value: year.toString(), disabled: true, key: year });
        for (const date of dates) {
          const formatedDate = format(getDateWithOffset(new Date(date)), 'yyyy-MM-dd');
          const splitDate = formatedDate.split('-');

          if (splitDate[0] === year.toString()) {
            options.push({
              label: getFormattedDate(isAsMonths, new Date(date), t, getDateWithOffset),
              value: format(getDateWithOffset(new Date(date)), 'yyyy-MM-dd'),
              key: date
            });
          }
        }
      }
    }
    /* let options = dates.map(date => ({
      label: getFormattedDate(isAsMonths, date, t, getDateWithOffset),
      value: format(getDateWithOffset(date), 'yyyy-MM-dd')
    }));
    */

    if (isAsMonths) {
      options = [{ label: t('common.all_months'), value: 'all' }, ...options];
    }

    setDates(options);
    setSelectedDate({
      label:
        selectedDate === 'all'
          ? t('common.all_months')
          : getFormattedDate(isAsMonths, selectedDate, t, getDateWithOffset),
      value: selectedDate === 'all' ? 'all' : format(getDateWithOffset(selectedDate), 'yyyy-MM-dd')
    });
  }, [dates, selectedDate, isAsMonths]);

  useEffect(() => {
    if (!locations || !locations.length) {
      return;
    }

    /* eslint-disable no-param-reassign */
    const reducedLocations = locations.reduce(
      (acc, loc) => {
        const forSelect = {
          label: loc.name,
          value: loc.id
        };

        acc.forSelect.push(forSelect);

        if (loc.id.toString() === selectedLocation) {
          acc.selected = forSelect;
        }

        return acc;
      },
      {
        forSelect: [
          {
            label: t('common.all_states'),
            value: 'all'
          }
        ],
        selected: {
          label: t('common.all_states'),
          value: 'all'
        }
      }
    );
    /* eslint-enable no-param-reassign */

    setLocations(reducedLocations.forSelect);
    setSelectedLocation(reducedLocations.selected);
  }, [locations, selectedLocation]);

  return (
    <FilterWrapper withPadding={withPadding}>
      {selectedLocationValue ? (
        <Select
          isHidden={isUpcoming}
          options={locationsForDropdown}
          values={[selectedLocationValue]}
          onChange={onSelectLocation}
          isRedLabel={redLabelLocation}
          isLocation
        >
          <Svg id="location" />
        </Select>
      ) : null}

      {selectedDateValue ? (
        <Select
          options={datesForDropdown}
          onChange={onSelectDate}
          values={[selectedDateValue]}
          isRedLabel={redLabelDate}
          isCalendar
          disabledLabel="Disabled"
        >
          <Svg id="calendar" />
        </Select>
      ) : null}
    </FilterWrapper>
  );
};

MoviesDatesFilter.propTypes = propTypes;
MoviesDatesFilter.defaultProps = defaultProps;
export default MoviesDatesFilter;
