import { ApiError, AuthError } from './errors';

/**
 * Handles API errors

 * @param res {Response|Error}
 * @throws {ApiError|AuthError|Error}
 */
export const handleErrorResponse = async res => {
  if (res instanceof Response && !res.ok) {
    let code;

    let message;

    let errors;

    let type;

    try {
      const body = JSON.parse(await res.text());

      ({ code, message, errors, type } = body);
    } catch {
      throw res;
    }

    if (res.status === 401) {
      throw new AuthError(message, code);
    }

    throw new ApiError(message, code, res.url, errors, type);
  }

  throw res;
};

/**
 * Handles API responses
 *
 * @param res {Response}
 * @returns {Promise|Response}
 */
export const handleResponse = res => {
  let result;

  if (res.status === 200 || res.status === 201 || res.status === 202) {
    const type = res.headers.get('content-type');

    if (type.indexOf('application/json') > -1) {
      result = res.json();
    } else if (type.indexOf('text/plain') > -1 || type.indexOf('text/html') > -1) {
      result = res.text();
    } else if (type.indexOf('application/pdf') > -1) {
      result = res.blob();
    }
  }

  if (res.status === 204) {
    result = res;
  }

  return result;
};
