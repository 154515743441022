import React from 'react';
import PropTypes from 'prop-types';
import { Svg, Button } from 'app/components';
import { Container, Title, Message, ButtonsRow } from './styles';

const propTypes = {
  message: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),

  children: PropTypes.element,
  title: PropTypes.string,
  btnText: PropTypes.string,
  click: PropTypes.func,
  withIcon: PropTypes.bool,
  iconId: PropTypes.string
};

const defaultProps = {
  message: null,
  iconId: 'complete',
  withIcon: true,
  title: '',
  btnText: null,
  click: () => {},
  children: null
};

const ModalTypical = ({ title, message, btnText, click, withIcon, iconId, children }) => (
  <Container>
    {withIcon ? <Svg className="icon" id={iconId} /> : null}
    {title ? <Title>{title}</Title> : null}

    <Message>
      {Array.isArray(message) ? message.map(text => <p>{text}</p>) : <p>{message}</p>}
    </Message>

    {children || (
      <Button isPrimary onClick={click} className="b-modal-default__btn">
        {btnText}
      </Button>
    )}
  </Container>
);

ModalTypical.ButtonsRow = ButtonsRow;
ModalTypical.defaultProps = defaultProps;
ModalTypical.propTypes = propTypes;
export default ModalTypical;
