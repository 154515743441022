import { connect } from 'react-redux';
import layoutModule from 'app/store/layout';
import Error from './Error.component';

const { clearError, reportError } = layoutModule.actions;

const mapStateToProps = ({ layout }) => ({
  error: layout.error || undefined
});

const mapDispatchToProps = {
  clearError,
  reportError
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Error);
