import { useCallback, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import storage from 'app/services/storage';
import { useRouter, useUrlParams } from 'app/hooks';

export default function() {
  const locations = useSelector(state => state.layout.locations);
  const {
    history: { replace },
    location: { search }
  } = useRouter();
  const { location } = useUrlParams('location');
  const urlSearchParams = useMemo(() => new URLSearchParams(search), [search]);
  const savedLocation = storage.getLocation();
  const saveLocation = storage.setLocation.bind(storage);

  const onLocationSelected = useCallback(
    value => {
      saveLocation(value);
      urlSearchParams.set('location', value);
      replace({ search: `?${urlSearchParams.toString()}` });
    },
    [saveLocation, urlSearchParams, replace]
  );

  useEffect(() => {
    let correctedLocation = null;
    const locationToCheck = location || savedLocation;

    if (!locations.length) {
      return;
    }

    if (!locations.some(l => l.id.toString() === locationToCheck)) {
      if (locationToCheck !== 'all') {
        saveLocation('all');
        correctedLocation = 'all';
        urlSearchParams.set('location', 'all');
        replace({ search: `?${urlSearchParams.toString()}` });
      }
    } else if (locationToCheck !== savedLocation) {
      saveLocation(locationToCheck);
    }

    if (!location) {
      urlSearchParams.set('location', correctedLocation || savedLocation);
      replace({ search: `?${urlSearchParams.toString()}` });
    }
  }, [locations, replace]);

  return {
    onLocationSelected
  };
}
