/* eslint-disable func-names */
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import storage from 'app/services/session';
import useUtils from 'app/hooks/useUtils';

export default function(order, isRedemption = false) {
  const payment = useSelector(state => state.payment.payment);
  const { getDateWithOffset } = useUtils();

  useEffect(() => {
    if (payment) {
      const {
        paymentPageUrl,
        pingTimeoutSecs,
        pingTimes,
        restrictions: { orderPaymentTTL }
      } = payment;

      storage.setOrder(order);
      storage.setPayment(getDateWithOffset(), orderPaymentTTL, pingTimeoutSecs, pingTimes);

      /**
       * Bug in Firefox, after go back from payment provides page from cache
       * https://madhatted.com/2013/6/16/you-do-not-understand-browser-history
       */
      window.addEventListener('unload', () => {});
      window.onpageshow = function(event) {
        if (event.persisted) {
          window.location.reload();
        }
      };
      window.location.href = paymentPageUrl;
    }

    storage.setBookingDetails(isRedemption);
  }, [payment, order, isRedemption, getDateWithOffset]);
}
