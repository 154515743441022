import { combineReducers } from 'redux';
import { combineEpics } from 'redux-observable';

import layout from './layout';
import auth from './auth';
import cinemas from './cinemas';
import movies from './movies';
import watchlist from './watchlist';
import user from './user';
import sessions from './sessions';
import order from './order';
import payment from './payment';
import bookings from './bookings';
import search from './search';
import information from './information';
import pdf from './pdf';
import seatPlan from './seatPlan';
import events from './events';

export const epics = combineEpics(
  ...Object.values(layout.epics),
  ...Object.values(auth.epics),
  ...Object.values(cinemas.epics),
  ...Object.values(movies.epics),
  ...Object.values(watchlist.epics),
  ...Object.values(user.epics),
  ...Object.values(sessions.epics),
  ...Object.values(order.epics),
  ...Object.values(payment.epics),
  ...Object.values(bookings.epics),
  ...Object.values(search.epics),
  ...Object.values(information.epics),
  ...Object.values(pdf.epics),
  ...Object.values(seatPlan.epics),
  ...Object.values(events.epics)
);

export const reducers = combineReducers({
  [layout.NAME]: layout.reducer,
  [cinemas.NAME]: cinemas.reducer,
  [movies.NAME]: movies.reducer,
  [watchlist.NAME]: watchlist.reducer,
  [user.NAME]: user.reducer,
  [sessions.NAME]: sessions.reducer,
  [order.NAME]: order.reducer,
  [payment.NAME]: payment.reducer,
  [bookings.NAME]: bookings.reducer,
  [search.NAME]: search.reducer,
  [information.NAME]: information.reducer,
  [pdf.NAME]: pdf.reducer,
  [seatPlan.NAME]: seatPlan.reducer,
  [events.NAME]: events.reducer
});
