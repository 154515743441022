import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Svg } from 'app/components';
import { BlockImageWithLoader, BlockImageWithLoaderInner } from './styles';

const propTypes = {
  type: PropTypes.string,
  imgSrc: PropTypes.string,
  imgTitle: PropTypes.string
};

const defaultProps = {
  type: 'movie',
  imgSrc: '',
  imgTitle: ''
};

const printIcon = type => {
  if (type === 'cinema') {
    return <Svg id="no-cinema" />;
  }

  return <Svg id="movie" />;
};

const ImageWithLoader = ({ type, imgSrc, imgTitle, ...props }) => {
  const [isLoaded, setStatusLoad] = useState(false);
  const [isLoadError, setStatusError] = useState(false);
  const onLoadImage = useCallback(() => setStatusLoad(true), []);
  const onErrorImage = useCallback(() => setStatusError(true), []);

  useEffect(() => {
    setStatusLoad(false);
    setStatusError(false);
  }, [imgSrc]);

  return (
    <BlockImageWithLoader {...props}>
      <BlockImageWithLoaderInner type={type}>
        {!isLoaded && !isLoadError && <Svg id="preloader" />}
        {isLoadError && printIcon(type)}
        <img
          src={imgSrc || ''}
          alt={imgTitle}
          onLoad={onLoadImage}
          onError={onErrorImage}
          className="b-image-with-loader__img"
        />
      </BlockImageWithLoaderInner>
    </BlockImageWithLoader>
  );
};

ImageWithLoader.propTypes = propTypes;
ImageWithLoader.defaultProps = defaultProps;
export default ImageWithLoader;
