import styled from 'styled-components';

export const Wrapper = styled.section.attrs({
  className: 's-no-internet'
})`
  margin: auto 0;
  padding: 120px 0;
  text-align: center;

  .svg-no-internet {
    display: block;
    width: 96px;
    height: 96px;
    margin: ${({ theme }) => theme.indent.l} auto;
  }
`;

export const Title = styled.h1.attrs({
  className: 's-no-internet__title'
})`
  color: ${({ theme }) => theme.color.textPrimary};

  font-size: 2.4rem;
  font-weight: 700;
  letter-spacing: 0.1px;
  text-transform: uppercase;

  @media screen and (max-width: ${({ theme }) => theme.breakPoints.sm.width}) {
    font-size: 1.6rem;
    line-height: 1.5;
  }
`;
