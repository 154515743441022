export default () => ({
  country: 'rou',
  intl: {
    fallbackLng: 'ro',
    locales: [
      {
        locale: 'en',
        label: 'English'
      },
      {
        locale: 'ro',
        label: 'Română'
      }
    ]
  },
  api: {
    user: {
      workZipCode: 'ROU'
    }
  },

  serverTimeZone: 3,

  footer: {
    copyright: '© Cineplexx România S.R.L 2019',
    apps: {
      android: 'https://play.google.com/store/apps/details?id=ro.cineplexx.app',
      ios: 'https://itunes.apple.com/app/id1473262156'
    }
  },

  supportInfo: {
    contactUs: {
      email: 'cinema@cineplexx.ro'
    }
  },

  form: {
    validation: {
      zip: 6
    }
  },

  wizard: {
    stepTickets: {
      warnings: {
        en: [
          {
            title:
              '<strong>Cineplexx Titan || <span class="_accent">Attention!</span></strong> The 1 + 1 offer is only available at <strong>Cineplexx Titan (Bucharest)</strong> for tickets purchased at the desk, not online!',
            description:
              'Pay at the cinema to receive a bonus ticket for every cinema ticket purchased'
          }
        ],
        ro: [
          {
            title:
              '<strong>Cineplexx Titan || <span class="_accent">Atenție!</span></strong> Oferta 1 + 1 este disponibilă doar la <strong>Cineplexx Titan (București)</strong> pentru biletele achiziționate la casele de bilete, nu și online!',
            description:
              'Pentru a beneficia de un bilet bonus pentru fiecare bilet de film cumpărat, plătește la cinema.'
          }
        ]
      }
    },

    stepCheckout: {
      messages: {
        en: [
          'Tickets purchased online cannot be changed or canceled and their value will not be refunded.'
        ],
        ro: [
          'Biletele achiziționate online nu pot fi schimbate sau anulate, iar contravaloarea acestora nu va fi returnată.'
        ]
      }
    }
  }
});
