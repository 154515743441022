import styled from 'styled-components';
import { linkNormal } from 'app/theme';

export const FooterTag = styled.footer.attrs({
  className: 'footer'
})`
  ${({ isVisible }) => !isVisible && 'display: none;'}
`;

export const BlockMainWrapper = styled.div.attrs({
  className: 'footer__main-wrapper'
})`
  padding: ${({ theme }) => theme.indent.l} 0;
  background-color: ${({ theme }) => theme.color.bgSecondary};

  @media screen and (max-width: ${({ theme }) => theme.breakPoints.md.width}) {
    & {
      padding: ${({ theme }) => theme.indent.m} 0;
    }
  }
`;

export const BlockMainLinks = styled.div.attrs({
  className: 'footer__main-links'
})`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0 -8px;

  & > .footer__main-inner {
    padding: 0 8px;
    width: 25%;
  }

  .footer__main-links-title {
    font: 700 1.6rem/1.44 BrandonGrotesque, sans-serif;
    text-transform: uppercase;
    color: ${({ theme }) => theme.color.textPrimary};
  }

  @media screen and (max-width: ${({ theme }) => theme.breakPoints.md.width}) {
    & > .footer__main-inner:not(._store-links) {
      display: none;
    }

    & > .footer__main-inner._store-links {
      width: 100.3333%;
      text-align: center;
    }
  }
`;

export const ListLinks = styled.ul`
  margin-top: 10px;
`;

export const ListLinksItem = styled.li`
  display: block;
  ${({ withSvg }) => withSvg && 'font-size: 0;'}

  & + & {
    margin: ${({ withSvg, theme }) => (withSvg ? theme.indent.m : theme.indent.s)} 0 0 0;
  }

  .l-footer-main__link {
    ${linkNormal}

    & > .svg-appstore,
    & > .svg-playstore {
      width: 161px;
      height: 48px;
      transition: box-shadow 0.2s ease-out, transform 0.2s ease-out;

      &:hover {
        box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.7);
        transform: translateY(-3px);
      }
    }
  }

  .l-footer-main__link._active {
    pointer-events: none;
    color: ${({ theme }) => theme.color.textPrimary};
  }

  @media screen and (max-width: ${({ theme }) => theme.breakPoints.md.width}) {
    display: inline-block;

    & + & {
      margin: 0 0 0 ${({ theme }) => theme.indent.m};
    }

    .l-footer-main__link {
      & > .svg-appstore,
      & > .svg-playstore {
        width: 134px;
        height: 40px;
      }
    }
  }
`;

export const BlockSecondaryWrapper = styled.div`
  padding: 20px 0;
  background-color: ${({ theme }) => theme.color.bgPrimary};
  color: ${({ theme }) => theme.color.textSecondary};

  & > .container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
  }

  @media screen and (max-width: 1200px) {
    & > .container {
      text-align: center;
    }

    .footer__secondary-copyright {
      order: 1;
      width: 100%;
      margin-top: ${({ theme }) => theme.indent.m};
    }

    .b-contacts {
      width: 100%;
    }

    .l-social {
      width: 100%;
      margin: 0 auto ${({ theme }) => theme.indent.m};
    }
  }
`;

export const Copyright = styled.div.attrs({
  className: 'footer__secondary-copyright'
})`
  font-size: 1.5rem;
  line-height: 1.33;
`;

export const Contacts = styled.div.attrs({
  className: 'b-contacts'
})`
  .b-contacts__link {
    ${linkNormal};
  }

  .b-contacts__link + .b-contacts__link {
    margin: 0 0 0 24px;
  }

  @media screen and (max-width: ${({ theme }) => theme.breakPoints.sm.width}) {
    .b-contacts__link {
      display: block;
    }

    .b-contacts__link + .b-contacts__link {
      margin: 8px 0 0 0;
    }
  }
`;
