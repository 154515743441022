import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import { sublistItemInner, withChevron, withChevronHover } from 'app/theme';

export const LoggedWrapper = styled.div.attrs({ className: 'header__logged' })`
  display: flex;
  align-items: center;
  position: relative;
  cursor: default;
  ${({ chevron }) => chevron && withChevron}

  & > .svg-user {
    vertical-align: middle;
    width: 24px;
    height: 24px;
    margin-right: ${({ theme }) => theme.indent.s};
    fill: ${({ theme }) => theme.color.rgbaWhite('.8')};
  }

  & > span {
    vertical-align: middle;
    font-size: 1.6rem;
    color: ${({ theme }) => theme.color.textPrimary};
  }

  & > .l-sublist {
    right: 0;
    left: auto;
  }

  &._hover {
    & > span {
      ${({ chevron }) => chevron && withChevronHover}
    }

    & > .l-sublist {
      opacity: 1;
      pointer-events: auto;
    }
  }

  @media screen and (max-width: ${({ theme }) => theme.breakPoints.md.width}) {
    position: static;

    & > .svg-user {
      display: none;
    }

    & > .l-sublist {
      top: 50px;
      right: 16px;
    }
  }

  @media screen and (max-width: ${({ theme }) => theme.breakPoints.sm.width}) {
    & > .l-sublist {
      right: 8px;
    }
  }
`;

export const SearchLink = styled(NavLink)`
  margin-right: ${({ theme }) => theme.indent.l};

  & > .svg-search {
    width: 25px;
    height: 25px;
    fill: ${({ theme }) => theme.color.rgbaWhite('.8')};
    transition: fill 0.2s ease-out;
  }

  &:hover,
  &._active {
    & > .svg-search {
      fill: ${({ theme }) => theme.color.rgbaWhite('1')};
    }
  }

  &._active {
    pointer-events: none;
  }

  @media screen and (max-width: ${({ theme }) => theme.breakPoints.md.width}) {
    display: none;
  }
`;

export const BlockControlWrapper = styled.div.attrs({
  className: 'header__control'
})`
  display: flex;
  align-items: center;
`;

export const LanguageSwitcher = styled.div.attrs({
  className: 'header__language-switcher'
})`
  display: flex;
  align-items: center;
  position: relative;
  cursor: default;
  margin-right: 16px;
  ${({ chevron }) => chevron && withChevron}

  .svg-language {
    width: 24px;
    height: 24px;
    fill: ${({ theme }) => theme.color.rgbaWhite('.8')};
  }

  .header__logged + & {
    margin-left: ${({ theme }) => theme.indent.l};
  }

  .header__loggedout + & {
    margin-left: ${({ theme }) => theme.indent.m};
  }

  & > span {
    display: inline-block;
    width: 40px;
    font-size: 1.6rem;
    font-weight: 700;
    text-transform: uppercase;
    margin-left: 4px;
  }

  & > .l-sublist {
    right: 0;
    left: auto;
  }

  &._hover {
    & > span {
      ${({ chevron }) => chevron && withChevronHover}
    }

    & > .l-sublist {
      opacity: 1;
      pointer-events: auto;
    }
  }
`;

export const NavTag = styled.nav.attrs({
  className: 'header__nav'
})`
  @media screen and (max-width: ${({ theme }) => theme.breakPoints.md.width}) {
    display: none;
  }
`;

export const NavList = styled.ul.attrs({
  className: 'l-header-nav'
})``;
export const NavListItem = styled.li.attrs({
  className: 'l-header-nav__item'
})`
  font-weight: 700;
  line-height: 1.5;
  color: #fff;
  text-transform: uppercase;
  position: relative;

  & > a,
  & > span {
    font-size: 1.6rem;
    color: ${({ theme }) => theme.color.textSecondary};
    transition: color 0.2s ease-out;
    cursor: pointer;
  }

  a._active {
    color: ${({ theme }) => theme.color.textPrimary};
    pointer-events: none;
  }

  & > .l-sublist {
    left: 0;
  }

  & + & {
    margin-left: 24px;
  }

  ${({ chevron }) => chevron && withChevron};

  &:hover {
    & > a,
    & > span {
      color: ${({ theme }) => theme.color.textPrimary};
      ${({ chevron }) => chevron && withChevronHover};
    }
  }

  &._hover {
    & > .l-sublist {
      opacity: 1;
      pointer-events: auto;
    }
  }
`;

export const SubList = styled.ul.attrs({
  className: 'l-sublist'
})`
  width: 288px;
  position: absolute;
  top: 46px;
  border-radius: 8px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.5), 0 0 4px 0 rgba(0, 0, 0, 0.5);
  background-color: ${({ theme }) => theme.color.bgPopup};
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.2s ease-out;

  &::before {
    content: '';
    width: 100%;
    height: 30px;
    position: absolute;
    top: -30px;
  }

  @media screen and (max-width: ${({ theme }) => theme.breakPoints.md.width}) {
    top: 37px;

    &::before {
      display: none;
    }
  }
`;

export const SubListItem = styled.li.attrs({
  className: 'l-sublist__item'
})`
  display: block;

  & > .l-sublist__link {
    ${sublistItemInner}

    &._active {
      background-color: ${({ theme }) => theme.color.rgbaWhite('.05')};
    }

    .header__logged & {
      justify-content: flex-start;
    }
  }

  .l-sublist__link {
    & > .svg-account,
    & > .svg-tickets,
    & > .svg-history,
    & > .svg-logout {
      width: 24px;
      height: 24px;
      margin-right: 8px;
      fill: ${({ theme }) => theme.color.accent};
    }
  }

  & > button {
    & > .svg-checkmark {
      width: 12px;
      height: 12px;
      fill: ${({ theme }) => theme.color.accent};
    }
  }

  & + & {
    border-top: 1px solid ${({ theme }) => theme.color.borderDark};
  }

  &:first-child > .l-sublist__link {
    border-radius: 7px 7px 0 0;
  }

  &:last-child > .l-sublist__link {
    border-radius: 0 0 7px 7px;
  }

  &:only-child > .l-sublist__link {
    border-radius: 7px;
  }
`;
