import styled from 'styled-components';

export default styled.div.attrs({
  className: 'b-title-with-poster'
})`
  display: flex;
  align-items: center;

  & + .carousel {
    margin-top: ${({ theme }) => theme.indent.m};
  }

  .b-image-with-loader {
    flex-shrink: 0;
    width: 100px;
    margin-right: ${({ theme }) => theme.indent.m};

    .l-entity & {
      width: 85px;
    }
  }

  .b-title-with-poster__title {
    font-size: 2.4rem;
    font-weight: 700;
    line-height: 1.17;
    letter-spacing: 1px;
    color: ${({ theme }) => theme.color.textPrimary};
    text-transform: uppercase;

    .l-entity & {
      font-size: 2rem;
    }
  }

  .b-title-with-poster__genre {
    margin-top: 6px;
  }

  .b-title-with-poster__duration {
    margin-top: 5px;
  }

  @media screen and (max-width: ${({ theme }) => theme.breakPoints.md.width}) {
    .b-title-with-poster__title {
      font-size: 2rem;
    }
  }

  @media screen and (max-width: ${({ theme }) => theme.breakPoints.xs.width}) {
    .b-image-with-loader {
      width: 75px;
    }

    .b-title-with-poster__title {
      font-size: 1.6rem;
    }

    .b-title-with-poster__genre {
      font-size: 1.4rem;
    }

    .b-title-with-poster__duration {
      font-size: 1.4rem;
    }
  }
`;
