import styled from 'styled-components';

export const Player = styled.div`
  @media screen and (max-width: ${({ theme }) => theme.breakPoints.xs.width}) {
    .bitmovinplayer-container {
      font-size: 1.4rem !important;
    }
  }
`;

export const Wrapper = styled.div.attrs({
  className: 'b-vendor-video'
})`
  min-width: 320px;

  .b-vendor-video__thumb {
    width: 100%;
    padding-top: 56.3%;
    position: relative;
  }

  .b-vendor-video__thumb-img {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }
`;

export const Error = styled.div.attrs({
  className: 'b-vendor-video__error'
})`
  padding: 75px 16px 65px;

  .b-vendor-video__error-title {
    margin-bottom: ${({ theme }) => theme.indent.xl};
    font-size: 2.4rem;
    font-weight: 500;
    text-transform: uppercase;
  }

  @media screen and (max-width: ${({ theme }) => theme.breakPoints.sm.width}) {
    .b-vendor-video__error-title {
      margin-bottom: ${({ theme }) => theme.indent.l};
    }
  }

  @media screen and (max-width: ${({ theme }) => theme.breakPoints.sm.width}) {
    padding: 50px 16px 40px;

    .b-vendor-video__error-title {
      margin-bottom: ${({ theme }) => theme.indent.l};
      font-size: 1.8rem;
    }
  }
`;
