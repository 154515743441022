import 'react-app-polyfill/ie11';
import './polyfills';

import React, { Suspense } from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { ThemeProvider } from 'styled-components';
import ReactModal from 'react-modal';

import i18n from 'app/i18n';
import theme from 'app/theme';
import configureStore from 'app/configureStore';
import Routes from 'app/router';
import API from 'app/services/api';
import storage from 'app/services/storage';
import session from 'app/services/session';
import ConfigContext from 'app/context/config';
import UtilsContext from 'app/context/utils';
import SessionContext from 'app/context/session';
import GoogleAnalytics from 'app/services/GoogleAnalytics';
import AnalyticsReporter from 'app/services/AnalyticsReporter';
import NoopAnalytics from 'app/services/NoopAnalytics';
import { getConfiguredUtils } from 'app/hooks/useUtils';

export default function(config, appElement) {
  const {
    api: { endpoint },
    rootElementId,
    intl,
    serverTimeZone
  } = config;
  const api = new API(config, storage);
  const configuredUtils = getConfiguredUtils(endpoint, serverTimeZone, theme);
  const canUseCookies = storage.getCookiesAgreementStatus() === 'true';
  const Analytics = canUseCookies ? GoogleAnalytics : NoopAnalytics;
  const ga = new Analytics({
    id: config.googleAnalyticKey,
    date: configuredUtils.getDateWithOffset()
  });
  const reporter = new AnalyticsReporter(config.routes, ga);
  const store = configureStore(config, api, reporter, session, storage);

  ReactModal.setAppElement(`#${rootElementId}`);
  i18n(intl);

  render(
    <React.StrictMode>
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          <Suspense fallback={<div />}>
            <ConfigContext.Provider value={config}>
              <UtilsContext.Provider value={configuredUtils}>
                <SessionContext.Provider value={session}>
                  <Routes sendVisitedScreens={reporter.sendPage} />
                </SessionContext.Provider>
              </UtilsContext.Provider>
            </ConfigContext.Provider>
          </Suspense>
        </ThemeProvider>
      </Provider>
    </React.StrictMode>,
    appElement
  );
}
