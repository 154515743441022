import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import useConfig from 'app/hooks/useConfig';
import useLogout from 'app/hooks/useLogout';
import { onClickBehavior } from 'app/utils';
import { Svg } from 'app/components';
import { List, Item } from './styles';

const propTypes = {
  isRounded: PropTypes.bool
};

const defaultProps = {
  isRounded: false
};

const Menu = ({ isRounded }) => {
  const [t] = useTranslation();
  const { routes } = useConfig();
  const logout = useLogout();
  const clickBehavior = isRounded ? onClickBehavior : {};

  return (
    <List isRounded={isRounded} {...clickBehavior}>
      <Item>
        <NavLink className="menu__link" to={routes.user.pattern} activeClassName="_active">
          <Svg id="account" />
          <span>{t('common.my_account')}</span>
        </NavLink>
      </Item>

      <Item>
        <NavLink className="menu__link" to={routes.tickets.pattern} activeClassName="_active">
          <Svg id="tickets" />
          <span>{t('common.my_tickets')}</span>
        </NavLink>
      </Item>

      <Item>
        <NavLink className="menu__link" to={routes.history.pattern} activeClassName="_active" exact>
          <Svg id="history" />
          <span>{t('common.my_history')}</span>
        </NavLink>
      </Item>

      <Item>
        <button type="button" className="menu__link" onClick={logout}>
          <Svg id="logout" />
          <span>{t('common.logout')}</span>
        </button>
      </Item>
    </List>
  );
};

Menu.propTypes = propTypes;
Menu.defaultProps = defaultProps;
export default Menu;
