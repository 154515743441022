import {
  LOADING,
  MODAL,
  REMOVE_TOAST,
  CREATE_TOAST,
  SET_ERROR,
  CLEAR_ERROR,
  SET_ERROR_API,
  CLEAR_ERROR_API,
  REQUEST_LOCATIONS,
  SUCCESS_LOCATIONS,
  SET_ELEMENTS_VISIBILITY,
  REPORT_ERROR
} from './types';

export const setLoader = val => ({
  type: LOADING,
  payload: { val }
});

export const setError = (code, message, error) => ({
  type: SET_ERROR,
  payload: { code, message, error }
});
export const clearError = () => ({
  type: CLEAR_ERROR
});
export const reportError = (error, type) => ({
  type: REPORT_ERROR,
  payload: {
    error,
    type
  }
});

export const setModal = (name, visible) => ({
  type: MODAL,
  payload: {
    name,
    visible
  }
});

export const createToast = (type, message) => ({
  type: CREATE_TOAST,
  payload: {
    type,
    message
  }
});
export const removeToast = id => ({
  type: REMOVE_TOAST,
  payload: {
    id
  }
});

export const setErrorApi = error => ({
  type: SET_ERROR_API,
  payload: {
    error
  }
});
export const clearErrorApi = () => ({
  type: CLEAR_ERROR_API
});

export const requestLocations = () => ({
  type: REQUEST_LOCATIONS
});
export const successLocations = locations => ({
  type: SUCCESS_LOCATIONS,
  payload: {
    locations
  }
});

export const setElementsVisibility = ({ header, footer }) => ({
  type: SET_ELEMENTS_VISIBILITY,
  payload: {
    elementsVisibility: { header, footer }
  }
});
