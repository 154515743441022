import { BUY_SUCCESS, SET_PAYMENT_STATUS } from './types';

const initialState = {
  payment: null,
  status: null
};

export default function(state = initialState, { type, payload }) {
  switch (type) {
    case BUY_SUCCESS: {
      return {
        ...state,
        payment: payload.payment
      };
    }

    case SET_PAYMENT_STATUS: {
      return {
        ...state,
        status: payload.status
      };
    }

    default: {
      return state;
    }
  }
}
