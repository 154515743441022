export const loadScript = src =>
  new Promise((resolve, reject) => {
    const s = document.createElement('script');

    s.src = src;
    s.onload = resolve;
    s.onerror = reject;
    document.head.appendChild(s);
  });

export const getContainersInnerHTML = html => {
  return html.match(/<div class="container"[^>]*>([\s\S]*)<\/div>/)[1];
};
