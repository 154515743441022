import { catchError, mergeMap, concatMap, startWith } from 'rxjs/operators';
import { from, concat } from 'rxjs';
import { ofType } from 'redux-observable';

import { setLoader, handleErrors, createToast, buySuccess, setError } from './deps';
import {
  BOOKED_TICKETS_REQUEST,
  BOOKING_REQUEST,
  CANCEL_RESERVATION_REQUEST,
  BUY_RESERVATION_REQUEST,
  BAR_CODE_REQUEST,
  QR_CODE_REQUEST
} from './types';
import { bookedTicketsSuccess, cancelReservationSuccess, setBooking, setCode } from './actions';

export function fetchBookedTickets($action, $state, { api }) {
  const $apiBookings = api.getModuleByName('bookings');

  return $action.pipe(
    ofType(BOOKED_TICKETS_REQUEST),
    mergeMap(action => {
      const {
        payload: { cinemaId }
      } = action;

      return from($apiBookings.getByCinema(cinemaId)).pipe(
        concatMap(res => [bookedTicketsSuccess(res), setLoader(false)]),

        ...handleErrors(action),

        catchError(err => [setError(err.code, err.message, err), setLoader(false)]),

        startWith(setLoader(true))
      );
    })
  );
}

export function fetchBooking($action, $state, { api }) {
  const $apiBookings = api.getModuleByName('bookings');

  return $action.pipe(
    ofType(BOOKING_REQUEST),
    mergeMap(action => {
      const {
        payload: { cinemaId, bookingId }
      } = action;

      return from($apiBookings.fetchBooking(cinemaId, bookingId)).pipe(
        concatMap(booking => [setBooking(booking), setLoader(false)]),

        ...handleErrors(action),
        catchError(err => [setError(err.code, err.message, err), setLoader(false)]),

        startWith(setLoader(true))
      );
    })
  );
}

export const cancelReservation = ($action, $state, { api }) => {
  const $apiBookings = api.getModuleByName('bookings');

  return $action.pipe(
    ofType(CANCEL_RESERVATION_REQUEST),
    mergeMap(action => {
      const {
        payload: { cinemaId, bookingNumber }
      } = action;

      return from($apiBookings.cancelReservation(cinemaId, bookingNumber)).pipe(
        concatMap(reservation => [cancelReservationSuccess(reservation), setLoader(false)]),

        ...handleErrors(action),

        catchError(err => concat([createToast('warning', err.message), setLoader(false)])),

        startWith(setLoader(true))
      );
    })
  );
};

export const buyReservation = ($action, $state, { api }) => {
  const $apiBookings = api.getModuleByName('bookings');

  return $action.pipe(
    ofType(BUY_RESERVATION_REQUEST),
    mergeMap(action => {
      const {
        payload: { bookingId }
      } = action;

      return from($apiBookings.buyReservation(bookingId)).pipe(
        concatMap(purchase => [buySuccess(purchase), setLoader(false)]),

        ...handleErrors(action),

        catchError(err => concat([createToast('warning', err.message), setLoader(false)])),

        startWith(setLoader(true))
      );
    })
  );
};

export const fetchBarCode = ($action, $state, { api }) => {
  const $api = api.getModuleByName('barcode');

  return $action.pipe(
    ofType(BAR_CODE_REQUEST),
    mergeMap(action => {
      const {
        payload: { code }
      } = action;

      return from($api.fetchBarcode(code)).pipe(
        concatMap(res => [setCode(res), setLoader(false)]),

        ...handleErrors(action),

        catchError(err => concat([createToast('warning', err.message), setLoader(false)])),

        startWith(setLoader(true))
      );
    })
  );
};

export const fetchQRCode = ($action, $state, { api }) => {
  const $api = api.getModuleByName('barcode');

  return $action.pipe(
    ofType(QR_CODE_REQUEST),
    mergeMap(action => {
      const {
        payload: { code }
      } = action;

      return from($api.fetchQRCode(code)).pipe(
        concatMap(res => [setCode(res), setLoader(false)]),

        ...handleErrors(action),

        catchError(err => concat([createToast('warning', err.message), setLoader(false)])),

        startWith(setLoader(true))
      );
    })
  );
};
