import {
  TOP_MOVIES_REQUEST,
  NOW_PLAYING_MOVIES_REQUEST,
  UPCOMING_MOVIES_REQUEST,
  MOVIES_FETCH_SUCCESS,
  SET_UPCOMING_MOVIES,
  MOVIES_SET_DATE,
  MOVIES_RESET_STATE,
  SET_DAYS_SESSIONS,
  MOVIE_REQUEST,
  SET_MOVIE,
  DATE_LIST_REQUEST,
  SET_DATE_LIST,
  SESSIONS_REQUEST,
  SET_SESSIONS,
  DAYS_SESSIONS_REQUEST,
  EVENTS_SESSIONS_REQUEST
} from './types';

export const requestTopMovies = params => ({
  type: TOP_MOVIES_REQUEST,
  payload: { params }
});
export const requestNowPlayingMovies = params => ({
  type: NOW_PLAYING_MOVIES_REQUEST,
  payload: { params }
});
export const requestUpcomingMovies = params => ({
  type: UPCOMING_MOVIES_REQUEST,
  payload: { params }
});

export const successMoviesRequest = data => ({
  type: MOVIES_FETCH_SUCCESS,
  payload: data
});
export const setUpcomingMovies = ({ films, upcomingDates }) => ({
  type: SET_UPCOMING_MOVIES,
  payload: { films, upcomingDates }
});
export const setDate = date => ({
  type: MOVIES_SET_DATE,
  payload: {
    date
  }
});

export const resetStateMovies = () => ({
  type: MOVIES_RESET_STATE
});

export const requestEventSessions = ({ eventId, movieId }) => ({
  type: EVENTS_SESSIONS_REQUEST,
  payload: {
    eventId,
    movieId
  }
});
export const requestDaysSessions = movieId => ({
  type: DAYS_SESSIONS_REQUEST,
  payload: {
    movieId
  }
});
export const setDaysSessions = sessions => ({
  type: SET_DAYS_SESSIONS,
  payload: {
    sessions
  }
});

export const requestMovie = id => ({
  type: MOVIE_REQUEST,
  payload: {
    id
  }
});
export const setMovie = movie => ({
  type: SET_MOVIE,
  payload: {
    movie
  }
});

export const requestDateList = ({ movieId }) => ({
  type: DATE_LIST_REQUEST,
  payload: {
    movieId
  }
});
export const setDateList = dates => ({
  type: SET_DATE_LIST,
  payload: {
    dates
  }
});

export const requestSessions = ({ movieId, date }) => ({
  type: SESSIONS_REQUEST,
  payload: {
    movieId,
    date
  }
});
export const setSessions = sessions => ({
  type: SET_SESSIONS,
  payload: {
    sessions
  }
});
