import {
  CINEMAS_REQUEST,
  CINEMAS_SUCCESS,
  CINEMAS_WITH_MOVIES_REQUEST,
  SET_CINEMAS_WITH_MOVIES,
  DATES_REQUEST,
  SET_DATES,
  CINEMA_REQUEST,
  SET_CINEMA,
  MOVIE_SESSIONS_REQUEST,
  SET_MOVIE_SESSIONS,
  TOGGLE_FAVORITE_REQUEST,
  TOGGLE_FAVORITE_SUCCESS
} from './types';

export const requestCinemas = () => ({
  type: CINEMAS_REQUEST
});
export const successCinemas = cinemas => ({
  type: CINEMAS_SUCCESS,
  payload: { cinemas }
});

export const requestCinemasWithMovies = date => ({
  type: CINEMAS_WITH_MOVIES_REQUEST,
  payload: { date }
});
export const setCinemasWithMovies = cinemas => ({
  type: SET_CINEMAS_WITH_MOVIES,
  payload: { cinemas }
});

export const requestDates = ({ cinemaId, location } = {}) => ({
  type: DATES_REQUEST,
  payload: { cinemaId, location }
});
export const setDates = dates => ({
  type: SET_DATES,
  payload: { dates }
});

export const requestToggleFavorite = ({ id, favorite, loyaltyCode }) => ({
  type: TOGGLE_FAVORITE_REQUEST,
  payload: {
    id,
    favorite,
    loyaltyCode
  }
});
export const successToggleFavorite = ({ id, favorite, loyaltyCode }, favoriteList) => ({
  type: TOGGLE_FAVORITE_SUCCESS,
  payload: {
    favoriteList,
    request: {
      id,
      favorite,
      loyaltyCode
    }
  }
});

export const requestCinema = id => ({
  type: CINEMA_REQUEST,
  payload: {
    id
  }
});
export const setCinema = cinema => ({
  type: SET_CINEMA,
  payload: {
    cinema
  }
});

export const requestMovieSessions = (id, date) => ({
  type: MOVIE_SESSIONS_REQUEST,
  payload: {
    id,
    date
  }
});
export const setMovieSessions = sessions => ({
  type: SET_MOVIE_SESSIONS,
  payload: {
    sessions
  }
});
