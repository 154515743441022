import styled from 'styled-components';
import { sublistItemInner } from 'app/theme';

export const Item = styled.li`
  display: block;

  & > .menu__link {
    ${sublistItemInner}
    justify-content: flex-start;

    &._active {
      background-color: ${({ theme }) => theme.color.rgbaWhite('.05')};
    }

    & > .svg-account,
    & > .svg-tickets,
    & > .svg-history,
    & > .svg-logout {
      width: 24px;
      height: 24px;
      margin-right: 8px;
      fill: ${({ theme }) => theme.color.accent};
    }
  }

  & + & {
    border-top: 1px solid ${({ theme }) => theme.color.borderDark};
  }
`;

export const List = styled.ul.attrs({
  className: 'menu'
})`
  ${({ isRounded }) =>
    isRounded &&
    `
    ${Item} {
      &:first-child > .menu__link {
        border-radius: 7px 7px 0 0;
      }
    
      &:last-child > .menu__link {
        border-radius: 0 0 7px 7px;
      }
    
      &:only-child > .menu__link {
        border-radius: 7px;
      }
    }
  `}
`;
