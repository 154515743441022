import BaseAPI from '../BaseAPI';

/**
 * Api class for handling user
 */
class User extends BaseAPI {
  /**
   * Creates User instance
   *
   * @param props {Object}
   * @return {User}
   */
  constructor(props) {
    super(props);

    this.baseUrl = '/users';
  }

  /**
   * Retrieves all user's transactions
   *
   * @return {Promise<*|Error>}
   */
  getTransactions() {
    const url = `${this.baseUrl}/balance/transactions`;

    return this.get({ url });
  }

  /**
   * Delete user account
   *
   * @returns {Promise<*|Error>}
   */
  removeAccount() {
    const url = this.baseUrl;

    return this.remove({ url });
  }
}

export default User;
