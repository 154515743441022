import React, { useRef, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Player } from './styles';

const propTypes = {
  id: PropTypes.string.isRequired,
  isHidden: PropTypes.bool.isRequired,
  onVideoLoaded: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired
};

const TYPES = {
  INIT: 'INIT',
  PLAY: 'PLAY'
};

const PlayerVendor = ({ id, isHidden, onVideoLoaded, onError }) => {
  const ref = useRef(null);

  const onVideoLoadedCb = useCallback(() => {
    onVideoLoaded();
    window.$(ref.current).uvp(TYPES.PLAY);
  }, [onVideoLoaded]);

  /**
   * Init Player
   */
  useEffect(() => {
    if (ref.current) {
      window.$(ref.current).uvp(TYPES.INIT, {
        guid: id,
        onVideoLoaded: onVideoLoadedCb,
        onError
      });
    }
  }, [ref]);

  return <Player id="player" ref={ref} hidden={isHidden} />;
};

PlayerVendor.propTypes = propTypes;
export default PlayerVendor;
