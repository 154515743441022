import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Svg, Menu } from 'app/components';
import { onHoverBehavior } from 'app/utils';
import { SubList, LoggedWrapper } from '../styles';

const propTypes = {
  userName: PropTypes.string
};

const defaultProps = {
  userName: null
};

export default function UserDropdown ({ userName }) {
  const [t] = useTranslation();

  return (
    <LoggedWrapper chevron {...onHoverBehavior}>
      <Svg id="user" />
      <span className="_with-chevron">{`${t('header.hello')}, ${userName || ''}`}</span>

      <SubList as="div">
        <Menu isRounded />
      </SubList>
    </LoggedWrapper>
  );
};

UserDropdown.propTypes = propTypes;
UserDropdown.defaultProps = defaultProps;
