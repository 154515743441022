import React from 'react';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import useConfig from 'app/hooks/useConfig';
import { Container } from 'app/components';
import { BlockSecondaryWrapper, Copyright, Contacts } from './styles';

const buildMailTo = (email, subject) => `mailto:${email}?subject=${subject}`;

const BlockSecondary = () => {
  const [t] = useTranslation();
  const {
    routes,
    footer: {
      copyright
    },
    supportInfo: { contactUs }
  } = useConfig();

  return (
    <BlockSecondaryWrapper>
      <Container>
        <Copyright>{copyright}</Copyright>

        <Contacts>
          <NavLink to={routes.legal.pattern} className="b-contacts__link" activeClassName="_active">
            {t('common.legal_info')}
          </NavLink>
          <NavLink
            to={routes.impressum.pattern}
            className="b-contacts__link"
            activeClassName="_active"
          >
            {t('common.impressum')}
          </NavLink>

          <a
            href={buildMailTo(contactUs.email, t('footer.contact_us'))}
            className="b-contacts__link"
            target="_blank"
            rel="noopener noreferrer"
          >
            {t('footer.contact_us')}
          </a>

          <NavLink to={routes.b2b.pattern} className="b-contacts__link" activeClassName="_active">
            {t('footer.b2b')}
          </NavLink>
        </Contacts>
      </Container>
    </BlockSecondaryWrapper>
  );
};

export default BlockSecondary;
