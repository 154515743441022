import {
  SET_USER,
  CLEAR_USER,
  UPDATE_REQUEST,
  UPDATE_SUCCESS,
  CHANGE_PASSWORD_REQUEST,
  TRANSACTIONS_REQUEST,
  SET_TRANSACTIONS,
  CLEAR_TRANSACTIONS,
  DELETE_REQUEST,
  DELETE_SUCCESS
} from './types';

export const setUser = user => ({
  type: SET_USER,
  payload: {
    user
  }
});
export const clearUser = () => ({
  type: CLEAR_USER
});

export const updateUserRequest = (data, notifyForm) => ({
  type: UPDATE_REQUEST,
  payload: { data, notifyForm }
});
export const updateUserSuccess = (request, user) => ({
  type: UPDATE_SUCCESS,
  payload: {
    request,
    user
  }
});

export const deleteUserRequest = () => ({
  type: DELETE_REQUEST
});
export const deleteUserSuccess = () => ({
  type: DELETE_SUCCESS
});

export const changePasswordRequest = (data, notifyForm) => ({
  type: CHANGE_PASSWORD_REQUEST,
  payload: { data, notifyForm }
});

export const transactionsRequest = () => ({
  type: TRANSACTIONS_REQUEST
});
export const setTransactions = transactions => ({
  type: SET_TRANSACTIONS,
  payload: {
    transactions
  }
});
export const clearTransactions = () => ({
  type: CLEAR_TRANSACTIONS
});
