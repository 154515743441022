import routes from './routes';
import logo from './logo';

export default (apiEndpoint, googleAnalyticKey, googleMapKey) => ({
  rootElementId: 'vapc',
  country: null,
  intl: {
    fallbackLng: 'en',
    locales: [
      {
        locale: 'en',
        label: 'English'
      },
      {
        locale: 'ro',
        label: 'Română'
      }
    ]
  },
  api: {
    endpoint: apiEndpoint,
    version: '/api/v1',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8',
      'CINEPLEXX-Platform': 'WEB'
    },
    user: {
      workZipCode: null
    }
  },

  media: {
    imgPrefix: url => url,
    docsPrefix: url => `${apiEndpoint}/api/v1/media/files?path=${url}`
  },

  bonusCard: {
    enabled: false
  },

  browserError: 'Index occurs',
  googleMapKey,
  googleAnalyticKey,

  routes,

  serverTimeZone: null,

  footer: {
    copyright: '© Cinepromotion und Filmmarketing Ges.m.b.H. 2019',
    apps: {
      android: 'https://play.google.com/store/apps/details?id=at.cineplexx.app',
      ios: 'https://itunes.apple.com/at/app/cineplexx-at/id1457257579'
    }
  },

  supportInfo: {
    contactUs: {
      email: 'cineplexx@constantinfilm.at'
    }
  },

  form: {
    validation: {
      zip: null
    }
  },

  logo,

  companyName: 'Cineplexx',

  player: {
    jquery: '//cdnjs.cloudflare.com/ajax/libs/jquery/1.9.1/jquery.js',
    url: '//uvp-cineplexx.sf.apa.at/scripts/jquery.uvp.stable.js'
  },

  headerTypes: {
    ACCEPT_LANGUAGE: 'Accept-Language',
    AUTHORIZATION: 'Authorization',
    CONTENT_TYPE: 'Content-Type'
  }
});
