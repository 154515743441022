import { mergeMap, startWith, concatMap } from 'rxjs/operators';
import { forkJoin } from 'rxjs';
import { ofType } from 'redux-observable';
import { setAllEvents, setEventDetails } from './actions';
import { REQUEST_ALL_EVENTS, REQUEST_EVENT_DETAILS } from './types';
import {
  setLoader,
  setErrorApi,
  separateErrors,
  authErrorHandleAfterManualCatch,
  handleErrors
} from './deps';

function fetchAllEvents($action, $state, { api }) {
  const $apiEvents = api.getModuleByName('events');

  return $action.pipe(
    ofType(REQUEST_ALL_EVENTS),
    mergeMap(action => {
      const { params } = action.payload;

      return forkJoin(
        $apiEvents.fetchAll(params).catch(err => ({ err })),
        $apiEvents.fetchMonthsList().catch(err => ({ err })),
        authErrorHandleAfterManualCatch.bind(null, (allEvents, monthsList) => ({
          allEvents,
          monthsList
        }))
      ).pipe(
        concatMap(res => {
          const { data, errors, hasErrors } = separateErrors(res);

          return [setAllEvents(data), setErrorApi(hasErrors ? errors : null), setLoader(false)];
        }),

        ...handleErrors(action),

        startWith(setLoader(true))
      );
    })
  );
}

function fetchEventDetails($action, $state, { api }) {
  const $apiEvents = api.getModuleByName('events');

  return $action.pipe(
    ofType(REQUEST_EVENT_DETAILS),
    mergeMap(action => {
      const { eventId } = action.payload;

      return forkJoin(
        $apiEvents.fetchById(eventId).catch(err => ({ err })),
        $apiEvents.fetchMovies(eventId).catch(err => ({ err })),
        authErrorHandleAfterManualCatch.bind(null, (eventDetails, movies) => ({
          eventDetails,
          movies
        }))
      ).pipe(
        concatMap(res => {
          const { data, errors, hasErrors } = separateErrors(res);

          return [setEventDetails(data), setErrorApi(hasErrors ? errors : null), setLoader(false)];
        }),

        ...handleErrors(action),

        startWith(setLoader(true))
      );
    })
  );
}

export { fetchAllEvents, fetchEventDetails };
