import { mergeMap, catchError, concatMap, startWith } from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { of, from } from 'rxjs';

import { setLoader, createToast, handleErrors } from './deps';

import { setPlan, setLegend } from './actions';
import { PLAN_REQUEST, LEGEND_REQUEST } from './types';

export const fetchSeatPlan = ($action, $state, { api }) => {
  const $api = api.getModuleByName('seatPlan');

  return $action.pipe(
    ofType(PLAN_REQUEST),
    mergeMap(action => {
      const {
        payload: { cinemaId, sessionId, orderId }
      } = action;

      return from($api.getPlan(cinemaId, sessionId, orderId)).pipe(
        concatMap(res => {
          const streams = [setPlan(res), setLoader(false)];

          if (res.error) {
            streams.push(createToast('warning', res.error));
          }

          return streams;
        }),

        ...handleErrors(action),
        catchError(err =>
          of(createToast('warning', err.message.replace(':', '')), setLoader(false))
        ),

        startWith(setLoader(true))
      );
    })
  );
};

export function fetchSeatPlanLegend($action, $state, { api }) {
  const $api = api.getModuleByName('seatPlan');

  return $action.pipe(
    ofType(LEGEND_REQUEST),
    mergeMap(action => {
      const { cinemaId, sessionId } = action.payload;

      return from($api.getLegend(cinemaId, sessionId)).pipe(
        concatMap(res => [setLegend(res), setLoader(false)]),

        ...handleErrors(action),
        catchError(err =>
          of(createToast('warning', err.message.replace(':', '')), setLoader(false))
        ),

        startWith(setLoader(true))
      );
    })
  );
}
