import React from 'react';
import PropTypes from 'prop-types';
import { Container, Svg } from 'app/components';
import BlockNav from './components/BlockNav';
import BlockControl from './components/BlockControl';
import { HeaderTag, LogoLink, BlockMain } from './styles';

const propTypes = {
  cinemas: PropTypes.arrayOf(PropTypes.object).isRequired,
  locations: PropTypes.arrayOf(PropTypes.object).isRequired,
  savedLocation: PropTypes.string.isRequired,
  isLoggedIn: PropTypes.bool.isRequired,
  userName: PropTypes.string,
  isVisible: PropTypes.bool.isRequired
};

const defaultProps = {
  userName: null
};

const Header = ({ cinemas, locations, savedLocation, isLoggedIn, userName, isVisible }) => (
  <HeaderTag>
    <Container>
      <LogoLink to="/">
        <Svg id="logo" />
        <Svg id="logo-md" />
      </LogoLink>

      <BlockMain isVisible={isVisible}>
        <BlockNav cinemas={cinemas} locations={locations} savedLocation={savedLocation} />
        <BlockControl isLoggedIn={isLoggedIn} userName={userName} />
      </BlockMain>
    </Container>
  </HeaderTag>
);

Header.propTypes = propTypes;
Header.defaultProps = defaultProps;
export default Header;
