import React from 'react';
import PropTypes from 'prop-types';
import { MuiPickersUtilsProvider, DatePicker } from 'material-ui-pickers';
import DateFnsUtils from '@date-io/date-fns';
import { MuiThemeProvider } from '@material-ui/core';
import useUtils from 'app/hooks/useUtils';
import { useTranslation } from 'react-i18next';
import theme from './theme';

const propTypes = {
  value: PropTypes.instanceOf(Date).isRequired,
  onChange: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  maxDate: PropTypes.instanceOf(Date)
};

const defaultProps = {
  maxDate: null
};

const Calendar = ({ value, onChange, onClose, isOpen, maxDate }) => {
  const { getDateWithOffset } = useUtils;
  const [t] = useTranslation();

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <MuiThemeProvider theme={theme}>
        <DatePicker
          views={['year', 'month', 'day']}
          openTo="year"
          value={value}
          onChange={onChange}
          onClose={onClose}
          ref={picker => picker && isOpen && picker.open()}
          maxDate={maxDate || getDateWithOffset()}
          cancelLabel={t('common.cancel')}
        />
      </MuiThemeProvider>
    </MuiPickersUtilsProvider>
  );
};

Calendar.defaultProps = defaultProps;
Calendar.propTypes = propTypes;
export default Calendar;
