import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { NavLink, generatePath } from 'react-router-dom';
import useConfig from 'app/hooks/useConfig';
import { ImageWithLoader } from 'app/components';
import TitleWithPoster from './styles';

const toMinutes = time => {
  const h = Math.round(time / 60);
  const m = time - 60 * h > 0 ? time - 60 * h : '';

  return `${h}h${m ? ` ${m}m` : ''}`;
};
const propTypes = {
  date: PropTypes.string,
  location: PropTypes.string,
  entityDetails: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    title: PropTypes.string,
    titleCalculated: PropTypes.string,
    poster: PropTypes.string,
    posterImage: PropTypes.string,
    isInWatchlist: PropTypes.bool,
    genres: PropTypes.oneOfType([PropTypes.array, PropTypes.any]),
    runTime: PropTypes.number
  }),
  withEvent: PropTypes.shape({
    dates: PropTypes.string
  }),
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element)])
};

const defaultProps = {
  date: null,
  location: null,
  entityDetails: null,
  children: null,
  withEvent: null
};

const getFields = (entity, withEvent) => {
  if (withEvent) {
    return {
      id: entity.id,
      title: entity.title,
      poster: entity.poster,
      isInWatchlist: entity.isInWatchlist
    };
  }

  return {
    id: entity.id,
    title: entity.titleCalculated,
    poster: entity.posterImage,
    isInWatchlist: entity.isInWatchlist,
    genres: entity.genres ? entity.genres.join(', ') : null,
    runTime: toMinutes(entity.runTime)
  };
};

const TitleWithPosterComponent = ({ date, entityDetails, location, withEvent, children }) => {
  const [t] = useTranslation();
  const { routes } = useConfig();
  const { id, title, poster, genres, runTime } = getFields(entityDetails, withEvent);

  return (
    <TitleWithPoster>
      {date ? (
        <ImageWithLoader
          as={NavLink}
          to={{
            pathname: generatePath(routes.movieDetails.pattern, { id }),
            search: `?date=${date}&location=${location}`
          }}
          imgSrc={poster}
          imgTitle={title}
        />
      ) : (
        <ImageWithLoader imgSrc={poster} imgTitle={title || ''} />
      )}
      <div className="b-title-with-poster__inner">
        <h2 className="b-title-with-poster__title">{title || t('movies.no_title')}</h2>
        {genres ? <p className="b-title-with-poster__genre">{genres}</p> : null}
        {runTime ? <p className="b-title-with-poster__duration">{runTime}</p> : null}
        {withEvent ? <p className="b-title-with-poster__duration">{withEvent.dates}</p> : null}
        {children}
      </div>
    </TitleWithPoster>
  );
};

TitleWithPosterComponent.propTypes = propTypes;
TitleWithPosterComponent.defaultProps = defaultProps;
export default TitleWithPosterComponent;
