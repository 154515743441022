import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import useConfig from 'app/hooks/useConfig';
import { Svg, Button } from 'app/components';
import {
  SignupWrapper,
  RewardsLinkText,
  RewardsLink,
  RewardsLinkWrapper,
  RewardsPopupWrapper,
  RewardsPopupTitle,
  RewardsPopupInfo,
  RewardsPopupAdvantages,
  RewardsPopupLink
} from './styles';

export default function() {
  const [t] = useTranslation();
  const { routes } = useConfig();
  const [isSignUpActive, toggleSignUpActiveStatus] = useState(false);
  const signUpUrl = routes.registration.pattern;
  const isOnSignUpPage = (match, params) =>
    toggleSignUpActiveStatus(params.pathname.indexOf(signUpUrl) >= 0);

  return (
    <SignupWrapper>
      <RewardsLinkWrapper className={isSignUpActive ? '_active' : null}>
        <RewardsLink to={signUpUrl} isActive={isOnSignUpPage}>
          <Svg id="rewards" />
          <RewardsLinkText>
            <span>{t('common.sign_up')}</span>
          </RewardsLinkText>
        </RewardsLink>

        <RewardsPopupWrapper>
          <RewardsPopupTitle>{t('header.rewards.popupTitle')}</RewardsPopupTitle>
          <RewardsPopupInfo>
            <RewardsPopupAdvantages>
              <li className="l-benefits__item">
                <div className="l-benefits__item-inner">
                  <Svg id="tickets-online" />
                </div>
                <p className="l-benefits__item-text">{t('header.rewards.tickets')}</p>
              </li>

              <li className="l-benefits__item">
                <div className="l-benefits__item-inner">
                  <Svg id="movie" />
                </div>
                <p className="l-benefits__item-text">{t('header.rewards.movie')}</p>
              </li>

              <li className="l-benefits__item">
                <div className="l-benefits__item-inner">
                  <Svg id="rocket" />
                </div>
                <p className="l-benefits__item-text">{t('header.rewards.access')}</p>
              </li>

              <li className="l-benefits__item">
                <div className="l-benefits__item-inner">
                  <Svg id="wishlist" />
                </div>
                <p className="l-benefits__item-text">{t('header.rewards.wishlist')}</p>
              </li>
            </RewardsPopupAdvantages>
            <RewardsPopupLink>
              <Svg id="logo" />
              <Button to={routes.registration.pattern} isPrimary>
                {t('common.join')}
              </Button>
            </RewardsPopupLink>
          </RewardsPopupInfo>
        </RewardsPopupWrapper>
      </RewardsLinkWrapper>
    </SignupWrapper>
  );
};
