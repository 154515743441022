export const INFORMATION_LEGAL_REQUEST = 'information/LEGAL_REQUEST';
export const INFORMATION_LEGAL_SET = 'information/LEGAL_SET';

export const INFORMATION_IMPRESSUM_REQUEST = 'information/IMPRESSUM_REQUEST';
export const INFORMATION_IMPRESSUM_SET = 'information/IMPRESSUM_SET';

export const INFORMATION_B2B_REQUEST = 'information/B2B_REQUEST';
export const INFORMATION_B2B_SET = 'information/B2B_SET';

export const INFORMATION_PRICELIST_REQUEST = 'information/PRICELIST_REQUEST';
export const INFORMATION_PRICELIST_SET = 'information/INFORMATION_PRICELIST_SET';
