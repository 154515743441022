import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useConfig } from 'app/hooks';
import useUrlParams from 'app/hooks/useUrlParams';
import { AFTER_ROUTE_TRANSITION } from 'app/HOC/withRouteEvents';
import Header from 'app/components/Header';
import Footer from 'app/components/Footer';
import { Loader, CookiesAgreement, MobileNav } from 'app/components';
import Toasters from 'app/containers/Toasters';
import Wrapper from './styles';

const propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired
  }).isRequired,

  location: PropTypes.shape({
    search: PropTypes.string.isRequired,
    pathname: PropTypes.string.isRequired
  }).isRequired,

  children: PropTypes.element.isRequired,

  error: PropTypes.shape({
    code: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    message: PropTypes.string,
    error: PropTypes.object
  }),

  cinemas: PropTypes.arrayOf(PropTypes.object).isRequired,
  locations: PropTypes.arrayOf(PropTypes.object).isRequired,

  isLoading: PropTypes.bool.isRequired,
  userName: PropTypes.string,
  token: PropTypes.string,
  savedLocation: PropTypes.string,
  isLoggedIn: PropTypes.bool,

  isRespondedToCookiesAgreement: PropTypes.bool.isRequired,
  elementsVisibility: PropTypes.shape({
    header: PropTypes.bool.isRequired,
    footer: PropTypes.bool.isRequired
  }).isRequired,

  refreshToken: PropTypes.func.isRequired,
  fetchCinemas: PropTypes.func.isRequired,
  getLocations: PropTypes.func.isRequired,
  saveLocation: PropTypes.func.isRequired,
  acceptCookiesAgreement: PropTypes.func.isRequired,
  declineCookiesAgreement: PropTypes.func.isRequired,
  listen: PropTypes.func.isRequired,
  legalInfo: PropTypes.arrayOf(
    PropTypes.shape({
      doc: PropTypes.string,
      docType: PropTypes.string,
      label: PropTypes.string
    })
  ).isRequired,
  getLegalInfo: PropTypes.func.isRequired
};

const defaultProps = {
  error: null,
  userName: null,
  token: null,
  savedLocation: null,
  isLoggedIn: null
};

const Layout = ({
  history: { push },
  location,

  children,
  error,
  cinemas,
  locations,

  isLoading,
  userName,
  token,
  savedLocation,
  isLoggedIn,
  isRespondedToCookiesAgreement,
  elementsVisibility,

  refreshToken,
  fetchCinemas,
  getLocations,
  saveLocation,
  acceptCookiesAgreement,
  declineCookiesAgreement,
  listen,
  legalInfo,
  getLegalInfo
}) => {
  const { pathname } = location;
  const { routes } = useConfig();
  const [isVisible, setVisibility] = useState(false);
  const { location: searchLocation } = useUrlParams('location');

  useEffect(() => {
    if (searchLocation && savedLocation !== searchLocation) {
      saveLocation(searchLocation);
    }
  }, []);

  useEffect(() => {
    listen(location);

    const listener = e => listen(e.detail.currentLocation);

    window.addEventListener(AFTER_ROUTE_TRANSITION, listener, false);

    return () => window.removeEventListener(AFTER_ROUTE_TRANSITION, listener, false);
  }, []);

  useEffect(() => getLegalInfo(), []);

  useEffect(() => window.scrollTo(0, 0), [pathname]);

  useEffect(() => {
    if (error) {
      push(routes.error.pattern);
    }
  }, [error]);

  useEffect(() => {
    if (token && !isLoggedIn) {
      refreshToken();
    }
  }, []);

  useEffect(() => {
    if ((!isLoggedIn && !token) || (isLoggedIn && token)) {
      fetchCinemas();
      getLocations();
      setVisibility(true);
    }
  }, [isLoggedIn, token]);

  useEffect(() => {
    const listener = () => push(routes.no_internet.pattern);

    window.addEventListener('offline', listener, false);

    return () => window.removeEventListener('offline', listener, false);
  }, [push, routes]);

  const cookiePolicyInfo = legalInfo.find(info => info.type === 'cookiePolicy');

  return (
    <Fragment>
      <Wrapper isLoading={isLoading}>
        <Header
          cinemas={cinemas}
          locations={locations}
          savedLocation={savedLocation}
          isLoggedIn={isLoggedIn}
          userName={userName}
          isVisible={elementsVisibility.header}
        />
        {isVisible ? children : null}
        <Footer
          locations={locations}
          savedLocation={savedLocation}
          isVisible={elementsVisibility.footer}
        />

        <Toasters />
        {isRespondedToCookiesAgreement ? null : (
          <CookiesAgreement
            info={cookiePolicyInfo}
            acceptCookiesAgreement={acceptCookiesAgreement}
            declineCookiesAgreement={declineCookiesAgreement}
          />
        )}
        <MobileNav savedLocation={savedLocation} isVisible={elementsVisibility.footer} />
      </Wrapper>
      {isLoading && <Loader />}
    </Fragment>
  );
};

Layout.propTypes = propTypes;
Layout.defaultProps = defaultProps;
export default Layout;
