import {
  INFORMATION_LEGAL_REQUEST,
  INFORMATION_LEGAL_SET,
  INFORMATION_IMPRESSUM_REQUEST,
  INFORMATION_IMPRESSUM_SET,
  INFORMATION_B2B_REQUEST,
  INFORMATION_B2B_SET,
  INFORMATION_PRICELIST_REQUEST,
  INFORMATION_PRICELIST_SET
} from './types';

export const legalInfoContentRequest = () => ({
  type: INFORMATION_LEGAL_REQUEST
});
export const setLegalInfoContent = legalInfoContent => ({
  type: INFORMATION_LEGAL_SET,
  payload: {
    legalInfoContent
  }
});

export const impressumContentRequest = () => ({
  type: INFORMATION_IMPRESSUM_REQUEST
});
export const setImpressumContent = impressumContent => ({
  type: INFORMATION_IMPRESSUM_SET,
  payload: {
    impressumContent
  }
});

export const b2bContentRequest = () => ({
  type: INFORMATION_B2B_REQUEST
});
export const setB2bContent = b2bContent => ({
  type: INFORMATION_B2B_SET,
  payload: {
    b2bContent
  }
});

export const priceListContentRequest = id => ({
  type: INFORMATION_PRICELIST_REQUEST,
  payload: {
    id
  }
});
export const setPriceListContent = priceListContent => ({
  type: INFORMATION_PRICELIST_SET,
  payload: {
    priceListContent
  }
});
