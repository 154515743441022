import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Svg } from 'app/components';
import { Wrapper, Btn, Text } from './styles';

const propTypes = {
  min: PropTypes.number,
  max: PropTypes.number,
  disabled: PropTypes.bool,
  value: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  onMax: PropTypes.func
};

const defaultProps = {
  disabled: false,
  min: 0,
  max: 1000000000,
  onMax: () => {}
};

const InputNumber = ({ value, min, max, onChange, disabled, onMax }) => {
  const decrease = useCallback(() => {
    if (min < value && !disabled) {
      onChange(value - 1);
    }
  }, [value, min]);

  const increase = useCallback(() => {
    if (max > value && !disabled) {
      onChange(value + 1);
    } else {
      onMax();
    }
  }, [value, max]);

  return (
    <Wrapper>
      <Btn onClick={decrease} disabled={min === value}>
        <Svg id="minus" />
      </Btn>

      <Text>{value}</Text>

      <Btn onClick={increase}>
        <Svg id="plus" />
      </Btn>
    </Wrapper>
  );
};

InputNumber.defaultProps = defaultProps;
InputNumber.propTypes = propTypes;
export default React.memo(InputNumber);
