import { SEARCH_SUCCESS } from 'app/store/search/types';
import { REGISTER_SUCCESS } from 'app/store/auth/types';
import { UPDATE_SUCCESS } from 'app/store/user/types';
import { TOGGLE_FAVORITE_SUCCESS } from 'app/store/cinemas/types';
import { ANALYTICS_WATCHLIST_STATUS } from 'app/store/watchlist/types';
import { PURCHASE_SUCCESS } from 'app/store/payment/types';
import { CANCEL_RESERVATION_SUCCESS } from 'app/store/bookings/types';
import { BY_ORDER_SUCCESS, BY_BOOKING_SUCCESS } from 'app/store/pdf/types';
import { REPORT_ERROR } from 'app/store/layout/types';

const PAYMENT_TYPES = {
  credit: 'CREDIT_CARD',
  bonus: 'BONUS_CARD'
};

const PDF_TYPES = {
  ticket: 'tickets',
  reservation: 'reservation'
};

const analytics = (reporter, sessionStorage) => store => next => action => {
  const { type, payload } = action;

  switch (type) {
    case SEARCH_SUCCESS: {
      reporter.sendSearch(payload.request);
      break;
    }

    case REGISTER_SUCCESS: {
      reporter.sendRegistration(payload.request);
      break;
    }

    case UPDATE_SUCCESS: {
      reporter.sendUserUpdate(payload.request);
      break;
    }

    case TOGGLE_FAVORITE_SUCCESS: {
      const { favorite } = payload.request;

      reporter.sendCinemaFavorite(favorite);
      break;
    }

    case ANALYTICS_WATCHLIST_STATUS: {
      const { favorite } = payload.request;

      reporter.sendMovieFavorite(favorite);
      break;
    }

    case PURCHASE_SUCCESS: {
      const { booking } = payload;
      const { isRedemption } = sessionStorage.getBookingDetails() || {};
      const paymentType = booking.bonusCardBound ? PAYMENT_TYPES.bonus : PAYMENT_TYPES.credit;
      const amount = booking.tickets.length;

      /**
       * Reservation
       */
      if (!booking.isPaid) {
        reporter.sendReservation(amount, paymentType);
      }

      /**
       * Redemption
       */
      if (booking.isPaid && isRedemption) {
        reporter.sendRedemption(amount, paymentType);
      }

      /**
       * Payment
       */
      if (booking.isPaid && !isRedemption) {
        reporter.sendTicketPurchase(amount, paymentType);
      }

      break;
    }

    case CANCEL_RESERVATION_SUCCESS: {
      const { booking } = store.getState().bookings;
      const paymentType = booking.bonusCardBound ? PAYMENT_TYPES.bonus : PAYMENT_TYPES.credit;
      const amount = booking.tickets.length;

      reporter.sendCancelReservation(amount, paymentType);

      break;
    }

    case BY_ORDER_SUCCESS:
    case BY_BOOKING_SUCCESS: {
      const { booking } = store.getState().bookings;
      const typeOfPayment = booking.isPaid ? PDF_TYPES.ticket : PDF_TYPES.reservation;

      reporter.sendPDF(typeOfPayment);
      break;
    }

    case REPORT_ERROR: {
      const { error, type: errorType } = payload;

      reporter.sendError(error, errorType);
      break;
    }

    default: {
      break;
    }
  }

  return next(action);
};

export default analytics;
