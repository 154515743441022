import {
  BUY_REQUEST,
  BUY_SUCCESS,
  PURCHASE_REQUEST,
  PURCHASE_SUCCESS,
  RESERVE_REQUEST,
  PURCHASE_STATUS_REQUEST,
  PAYMENT_STATUS_REQUEST,
  SET_PAYMENT_STATUS
} from './types';

export const buyRequest = (userSessionId, email, notifyForm) => ({
  type: BUY_REQUEST,
  payload: {
    userSessionId,
    email,
    notifyForm
  }
});
export const buySuccess = payment => ({
  type: BUY_SUCCESS,
  payload: { payment }
});

export const reserveRequest = userSessionId => ({
  type: RESERVE_REQUEST,
  payload: { userSessionId }
});

export const purchaseRequest = userSessionId => ({
  type: PURCHASE_REQUEST,
  payload: {
    userSessionId
  }
});
export const purchaseSuccess = booking => ({
  type: PURCHASE_SUCCESS,
  payload: {
    booking
  }
});

export const purchaseStatusRequest = userSessionId => ({
  type: PURCHASE_STATUS_REQUEST,
  payload: {
    userSessionId
  }
});

/**
 *  Payment Status Actions
 */
export const paymentStatusRequest = userSessionId => ({
  type: PAYMENT_STATUS_REQUEST,
  payload: {
    userSessionId
  }
});
export const setPaymentStatus = status => ({
  type: SET_PAYMENT_STATUS,
  payload: {
    status
  }
});
