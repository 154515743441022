export const SET_USER = 'user/SET_USER';
export const CLEAR_USER = 'user/CLEAR_USER';

export const UPDATE_REQUEST = 'user/UPDATE_REQUEST';
export const UPDATE_SUCCESS = 'user/UPDATE_SUCCESS';
export const CHANGE_PASSWORD_REQUEST = 'user/CHANGE_PASSWORD_REQUEST';

export const TRANSACTIONS_REQUEST = 'user/TRANSACTIONS_REQUEST';
export const SET_TRANSACTIONS = 'user/SET_TRANSACTIONS';
export const CLEAR_TRANSACTIONS = 'user/CLEAR_TRANSACTIONS';

export const DELETE_REQUEST = 'user/DELETE_REQUEST';
export const DELETE_SUCCESS = 'user/DELETE_SUCCESS';
