import React from 'react';
import PropTypes from 'prop-types';
import { NavLink, generatePath } from 'react-router-dom';
import { Container, Svg } from 'app/components';
import useUrlParams from 'app/hooks/useUrlParams';
import useConfig from 'app/hooks/useConfig';
import MobileNav from './styles';

const propTypes = {
  savedLocation: PropTypes.string.isRequired,
  isVisible: PropTypes.bool.isRequired
};

const MobileNavComponent = ({ savedLocation, isVisible }) => {
  const { routes } = useConfig();
  const { date } = useUrlParams('date');

  return (
    <MobileNav isVisible={isVisible}>
      <Container>
        <nav className="b-mobile-nav__inner">
          <NavLink
            to={{
              pathname: generatePath(routes.movies.pattern),
              search: `&location=${savedLocation}${date ? `&date=${date}` : ''}`
            }}
            title={routes.movies.name}
            className="b-mobile-nav__link"
            exact
          >
            <Svg id="movie" />
          </NavLink>
          <NavLink
            to={{
              pathname: generatePath(routes.cinemas.pattern),
              search: `&location=${savedLocation}${date ? `&date=${date}` : ''}`
            }}
            title={routes.cinemas.name}
            className="b-mobile-nav__link"
            exact
          >
            <Svg id="pin" />
          </NavLink>
          <NavLink
            to={generatePath(routes.search.pattern)}
            title={routes.search.name}
            className="b-mobile-nav__link"
          >
            <Svg id="search" />
          </NavLink>
          <NavLink
            to={{
              pathname: generatePath(routes.events.pattern),
              search: `&location=${savedLocation}date=all`
            }}
            title={routes.search.name}
            className="b-mobile-nav__link"
          >
            <Svg id="events" />
          </NavLink>
          <NavLink
            to={generatePath(routes.account.pattern)}
            title={routes.account.name}
            className="b-mobile-nav__link"
          >
            <Svg id="user" />
          </NavLink>
        </nav>
      </Container>
    </MobileNav>
  );
};

MobileNavComponent.propTypes = propTypes;
export default MobileNavComponent;
