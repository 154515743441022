import { useCallback, useEffect, useMemo } from 'react';
import { useRouter, useUtils, useUrlParams } from 'app/hooks';
import { dateValidator } from 'app/utils';
import { format } from 'date-fns';

export default function(dates) {
  const { date } = useUrlParams('date');
  const {
    history: { replace },
    location: { search }
  } = useRouter();
  const { getDateWithOffset, getFallbackDate } = useUtils();

  const urlSearchParams = useMemo(() => new URLSearchParams(search), [search]);
  const isDateValid = useMemo(() => dateValidator(date), [date]);
  const isDateExist = useMemo(
    () => !dates.length || dates.some(d => format(getDateWithOffset(d), 'yyyy-MM-dd') === date),
    [dates, date]
  );

  const onDateSelected = useCallback(
    value => {
      urlSearchParams.set('date', value);
      replace({ search: `?${urlSearchParams.toString()}` });
    },
    [urlSearchParams, replace]
  );

  useEffect(() => {
    if (!isDateValid || !isDateExist) {
      urlSearchParams.set('date', getFallbackDate(dates));
      replace({ search: `?${urlSearchParams.toString()}` });
    }
  }, [dates, isDateValid, isDateExist, urlSearchParams, replace]);

  return {
    isDateValid,
    isDateExist,
    onDateSelected
  };
}
