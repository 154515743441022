import { useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import pdfModule from 'app/store/pdf';

const { clearPDF, requestByOrder, requestByBooking } = pdfModule.actions;

const download = (blob, filename = 'tickets.pdf') => {
  if (navigator.msSaveOrOpenBlob) {
    navigator.msSaveOrOpenBlob(blob, filename);
  } else {
    const link = document.createElement('a');
    const URL = window.URL || window.webkitURL;
    const downloadUrl = URL.createObjectURL(blob);

    link.href = downloadUrl;
    link.download = filename;

    document.body.appendChild(link);

    link.click();

    document.body.removeChild(link);
    URL.revokeObjectURL(downloadUrl);
  }
};

export default function({ cinemaId, bookingId, userSessionId }) {
  const dispatch = useDispatch();
  const pdf = useSelector(state => state.pdf);

  const cb = userSessionId
    ? requestByOrder.bind(null, userSessionId)
    : requestByBooking.bind(null, cinemaId, bookingId);
  const requestPDF = useCallback(() => {
    dispatch(cb());
  }, []);

  useEffect(() => {
    if (!pdf) return undefined;

    download(pdf);

    return () => {
      dispatch(clearPDF());
    };
  }, [pdf]);

  return requestPDF;
}
