import { SET_USER, CLEAR_USER, SET_TRANSACTIONS, CLEAR_TRANSACTIONS } from './types';

const initialState = {
  user: null,
  transactions: []
};

export default function(state = initialState, { type, payload }) {
  switch (type) {
    case SET_USER: {
      return {
        ...state,
        user: payload.user
      };
    }

    case CLEAR_USER: {
      return {
        ...state,
        user: null
      };
    }

    case SET_TRANSACTIONS: {
      return {
        ...state,
        transactions: payload.transactions
      };
    }

    case CLEAR_TRANSACTIONS: {
      return {
        ...state,
        transactions: []
      };
    }

    default: {
      return state;
    }
  }
}
