import React from 'react';
import PropTypes from 'prop-types';
import BlockMain from './BlockMain';
import BlockSecondary from './BlockSecondary';
import { FooterTag } from './styles';

const propTypes = {
  locations: PropTypes.arrayOf(PropTypes.object).isRequired,
  savedLocation: PropTypes.string.isRequired,
  isVisible: PropTypes.bool.isRequired
};

const Footer = ({ locations, savedLocation, isVisible }) => (
  <FooterTag isVisible={isVisible}>
    <BlockMain locations={locations} savedLocation={savedLocation} />
    <BlockSecondary />
  </FooterTag>
);

Footer.propTypes = propTypes;
export default Footer;
