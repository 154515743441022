import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import useConfig from 'app/hooks/useConfig';
import { Container, Button, Svg } from 'app/components';
import { Page, Title, Code } from './styles';

const propTypes = {
  error: PropTypes.shape({
    code: PropTypes.oneOfType([PropTypes.number.isRequired, PropTypes.string.isRequired]),
    message: PropTypes.string.isRequired,
    error: PropTypes.object.isRequired
  }),

  dev: PropTypes.shape({
    message: PropTypes.string,
    stack: PropTypes.string
  }),

  clearError: PropTypes.func.isRequired,
  reportError: PropTypes.func.isRequired
};

const defaultProps = {
  error: null,
  dev: null
};

const errorMock = {
  code: 404,
  message: 'error_page.errors.not_found',
  error: null
};

const devErrorMock = {
  code: 0,
  message: 'error_page.errors.ui'
};

const ERROR_TYPES = {
  ui: 'ui',
  network: 'network'
};

const Error = ({ error, clearError, dev, reportError }) => {
  const [t] = useTranslation();
  const { routes } = useConfig();
  const { message, code } = dev ? devErrorMock : error || errorMock;
  const fallbackUrl = routes.fallback.pattern;
  const buttonProps = dev
    ? {
        onClick: () => {
          window.location.pathname = fallbackUrl;
        }
      }
    : { to: fallbackUrl };

  useEffect(
    () => () => {
      clearError();
    },
    []
  );

  useEffect(() => {
    if (dev) {
      reportError(dev, ERROR_TYPES.ui);
    }

    if (error) {
      reportError(error.error, ERROR_TYPES.network);
    }
  }, [dev, error]);

  return (
    <Page>
      <Container className="container">
        <Title>{t(message)}</Title>
        <Code>{code}</Code>
        <Svg id="error" />
        <Button {...buttonProps} isDefault>
          {t('common.to_home_page')}
        </Button>
      </Container>
    </Page>
  );
};

Error.defaultProps = defaultProps;
Error.propTypes = propTypes;
export default Error;
