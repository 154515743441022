import React, { useEffect, useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { NavLink, generatePath } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import ConfigContext from 'app/context/config';
import useUrlParams from 'app/hooks/useUrlParams';
import { onHoverBehavior, onClickBehavior } from 'app/utils';
import { NavTag, NavList, NavListItem, SubList, SubListItem } from './styles';

const propTypes = {
  cinemas: PropTypes.arrayOf(PropTypes.object).isRequired,
  locations: PropTypes.arrayOf(PropTypes.object).isRequired,
  savedLocation: PropTypes.string.isRequired
};

const BlockNav = ({ cinemas, locations, savedLocation }) => {
  const [t] = useTranslation();
  const { routes } = useContext(ConfigContext);
  const { date } = useUrlParams('date');
  const [cinemasToShow, setCinemas] = useState([]);

  useEffect(() => {
    if (savedLocation === 'all') {
      setCinemas(cinemas);
    } else {
      /* eslint-disable no-param-reassign */
      const items = locations.reduce((res, l) => {
        if (l.id.toString() === savedLocation) {
          res = l.items;
        }

        return res;
      }, []);
      /* eslint-enable no-param-reassign */

      if (items.length) {
        setCinemas(cinemas.filter(c => items.some(i => i.toString() === c.id)));
      }
    }
  }, [locations, cinemas, savedLocation]);

  return (
    <NavTag>
      <NavList>
        <NavListItem>
          <NavLink
            to={{
              pathname: routes.movies.pattern,
              search: `?category=now&location=${savedLocation}${date ? `&date=${date}` : ''}`
            }}
            activeClassName="_active"
            exact
          >
            {t('common.movies')}
          </NavLink>
        </NavListItem>

        <NavListItem>
          <NavLink
            to={{
              pathname: routes.events.pattern,
              search: `?location=${savedLocation}&date=all`
            }}
            activeClassName="_active"
            exact
          >
            {t('common.events')}
          </NavLink>
        </NavListItem>

        <NavListItem {...onHoverBehavior} chevron>
          <span className="_with-chevron">
            <NavLink
              to={{
                pathname: routes.cinemas.pattern,
                search: `?location=${savedLocation}${date ? `&date=${date}` : ''}`
              }}
              activeClassName="_active"
              exact
            >
              {t('common.cinemas')}
            </NavLink>
          </span>

          <SubList>
            {cinemasToShow.length ? (
              cinemasToShow.map(({ id, name }) => (
                <SubListItem key={id} {...onClickBehavior}>
                  <NavLink
                    className="l-sublist__link"
                    to={{
                      pathname: generatePath(routes.cinemaDetails.pattern, { id }),
                      search: `${date ? `date=${date}` : ''}`
                    }}
                    activeClassName="_active"
                    exact
                  >
                    {name.toLowerCase()}
                  </NavLink>
                </SubListItem>
              ))
            ) : (
              <SubListItem>
                <NavLink className="l-sublist__link" to="#" activeClassName="_active" exact>
                  <span>{t('cinemas.no_cinemas')}</span>
                </NavLink>
              </SubListItem>
            )}
          </SubList>
        </NavListItem>
      </NavList>
    </NavTag>
  );
};

BlockNav.propTypes = propTypes;
export default BlockNav;
