import { SET_SESSION, CLEAR_SESSION, WIZARD_CLEAR, WIZARD_SET } from './types';

const initialState = {
  session: null,
  wizard: {
    isBonus: false,
    child: 0,
    adult: 0
  }
};

export default function(state = initialState, { type, payload }) {
  switch (type) {
    case SET_SESSION: {
      return {
        ...state,
        session: payload.session
      };
    }

    case CLEAR_SESSION: {
      return {
        ...state,
        session: null
      };
    }

    case WIZARD_SET: {
      return {
        ...state,
        wizard: payload.wizard
      };
    }

    case WIZARD_CLEAR: {
      return {
        ...state,
        wizard: {}
      };
    }

    default: {
      return state;
    }
  }
}
