import { SET_ORDER, CLEAR_ORDER } from './types';

const initialState = null;

export default function(state = initialState, { type, payload }) {
  switch (type) {
    case SET_ORDER: {
      return payload.order;
    }

    case CLEAR_ORDER: {
      return null;
    }

    default: {
      return state;
    }
  }
}
