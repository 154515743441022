import { createStore, applyMiddleware } from 'redux';
import { createEpicMiddleware } from 'redux-observable';
import { reducers, epics } from './store';
import loaderMiddleware from './middlewares/loader';
import analyticsMiddleware from './middlewares/analytics';

const INITIAL_STATE = {};

export default function(config, api, reporter, session) {
  const epicMiddleware = createEpicMiddleware({
    dependencies: {
      api,
      session
    }
  });

  const store = createStore(
    reducers,
    INITIAL_STATE,
    applyMiddleware(loaderMiddleware, analyticsMiddleware(reporter, session), epicMiddleware)
  );

  epicMiddleware.run(epics);

  return store;
}
