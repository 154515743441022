import { useContext } from 'react';
import { addHours, subYears, format } from 'date-fns';
import UtilsContext from 'app/context/utils';

export const getConfiguredUtils = (endpoint, serverTimeZoneOffset, theme) => {
  const getDateWithOffset = (dateStr = null) => {
    const localeTimeZoneOffset = new Date().getTimezoneOffset() / 60;
    const diffOffset = localeTimeZoneOffset + serverTimeZoneOffset;

    return addHours(new Date(dateStr || Date.now()), diffOffset);
  };

  const getFallbackDate = dates => {
    const fallbackDate = dates.length
      ? format(getDateWithOffset(dates[0]), 'yyyy-MM-dd')
      : format(getDateWithOffset(), 'yyyy-MM-dd');

    return fallbackDate;
  };

  const MAX_BIRTH = subYears(getDateWithOffset(), 3);
  const DEFAULT_BIRTH = subYears(getDateWithOffset(), 5);

  const buildContentStaticUrl = url => `${endpoint}${url}`;

  const getWindowWidth = () => {
    const {
      breakPoints: { md, sm, xs }
    } = theme;

    switch (true) {
      case window.matchMedia(`(max-width: ${xs.width})`).matches: {
        return 'xs';
      }
      case window.matchMedia(`(min-width: calc(${xs.width} + 1px)) and (max-width: ${sm.width})`)
        .matches: {
        return 'sm';
      }
      case window.matchMedia(`(min-width: calc(${sm.width} + 1px)) and (max-width: ${md.width})`)
        .matches: {
        return 'md';
      }
      default: {
        return 'xl';
      }
    }
  };

  return {
    getDateWithOffset,
    getFallbackDate,
    MAX_BIRTH,
    DEFAULT_BIRTH,
    buildContentStaticUrl,
    getWindowWidth
  };
};

export default () => useContext(UtilsContext);
