import {
  BOOKED_TICKETS_REQUEST,
  BOOKED_TICKETS_SUCCESS,
  BOOKED_TICKETS_CLEAR,
  BOOKING_REQUEST,
  BOOKING_SET,
  BOOKING_CLEAR,
  CANCEL_RESERVATION_REQUEST,
  CANCEL_RESERVATION_SUCCESS,
  CANCEL_RESERVATION_CLEAR,
  BUY_RESERVATION_REQUEST,
  BUY_RESERVATION_SUCCESS,
  BAR_CODE_REQUEST,
  QR_CODE_REQUEST,
  CODE_SET,
  CODE_CLEAR
} from './types';

export const bookedTicketsRequest = cinemaId => ({
  type: BOOKED_TICKETS_REQUEST,
  payload: { cinemaId }
});
export const bookedTicketsSuccess = tickets => ({
  type: BOOKED_TICKETS_SUCCESS,
  payload: { tickets }
});
export const bookedTicketsClear = () => ({
  type: BOOKED_TICKETS_CLEAR
});

export const bookingRequest = (cinemaId, bookingId) => ({
  type: BOOKING_REQUEST,
  payload: { cinemaId, bookingId }
});
export const setBooking = booking => ({
  type: BOOKING_SET,
  payload: {
    booking
  }
});
export const clearBooking = () => ({
  type: BOOKING_CLEAR
});

export const cancelReservationRequest = (cinemaId, bookingNumber) => ({
  type: CANCEL_RESERVATION_REQUEST,
  payload: {
    cinemaId,
    bookingNumber
  }
});
export const cancelReservationSuccess = reservation => ({
  type: CANCEL_RESERVATION_SUCCESS,
  payload: { reservation }
});
export const cancelReservationClear = () => ({
  type: CANCEL_RESERVATION_CLEAR
});

export const buyReservationRequest = bookingId => ({
  type: BUY_RESERVATION_REQUEST,
  payload: { bookingId }
});
export const buyReservationSuccess = buy => ({
  type: BUY_RESERVATION_SUCCESS,
  payload: { buy }
});

export const barcodeRequest = code => ({
  type: BAR_CODE_REQUEST,
  payload: { code }
});
export const QRCodeRequest = code => ({
  type: QR_CODE_REQUEST,
  payload: { code }
});
export const setCode = code => ({
  type: CODE_SET,
  payload: { code }
});
export const clearCode = () => ({
  type: CODE_CLEAR
});
